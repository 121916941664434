import React from "react";
import shape3 from "../../assets/img/shape/3.svg";
import feedback1 from "../../assets/img/testimonial/1.jpg";
import feedback2 from "../../assets/img/testimonial/2.jpg";
import feedback3 from "../../assets/img/testimonial/3.jpg";
import feedback4 from "../../assets/img/testimonial/4.jpg";
import feedback5 from "../../assets/img/testimonial/5.jpg";

const Testimonials = () => {
  return (
    <div>
      {/* <!-- testimonial section start hare --> */}
      <section className="testimonial-wrapper section-padding fix section-bg-4">
        <div className="container">
          <div
            className="nice-arrow-icon d-none d-lg-block wow fadeInDown"
            data-wow-duration="1.2s"
          >
            <img src={shape3} alt="3d bg shape" />
          </div>
          <div className="col-lg-8 col-xl-6 offset-xl-3 offset-lg-2 text-center">
            <div className="block-contents">
              <div
                className="section-title wow fadeInDown"
                data-wow-duration="1.2s"
              >
                <h2>What Our Clients Are Saying</h2>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="testimonial-carousel-active swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="single-testimoinal-item single-testimoinal-item--style2">
                    <div className="client-desig">
                      <div className="client-img bg-cover">
                        <img src={feedback1} alt="feedback-customer" />
                      </div>
                      <div className="client-name">
                        <h6>Ava Martinez</h6>
                        <span>Financial Planner</span>
                      </div>
                    </div>
                    <div className="feedback">
                      "LillyBot has revolutionized my crypto trading! The
                      automated trades are spot-on, and I’m seeing consistent
                      profits. Highly recommend!"
                    </div>
                    <div className="rating">
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="single-testimoinal-item single-testimoinal-item--style2">
                    <div className="client-desig">
                      <div className="client-img bg-cover">
                        <img src={feedback2} alt="feedback-customer" />
                      </div>
                      <div className="client-name">
                        <h6>Samuel Wilson</h6>
                        <span>Software engineer</span>
                      </div>
                    </div>
                    <div className="feedback">
                      "I was skeptical at first, but LillyBot exceeded my
                      expectations. The AI-driven trades are accurate, and the
                      platform is easy to use. A game-changer for sure!"
                    </div>
                    client
                    <div className="rating">
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="single-testimoinal-item single-testimoinal-item--style2">
                    <div className="client-desig">
                      <div className="client-img bg-cover">
                        <img src={feedback3} alt="feedback-customer" />
                      </div>
                      <div className="client-name">
                        <h6>Matthew Turner</h6>
                        <span>App Developer</span>
                      </div>
                    </div>
                    <div className="feedback">
                      "LillyBot makes crypto trading effortless. I’m impressed
                      with the high-volume trading and the consistent returns.
                      It’s like having a personal trading assistant!"
                    </div>
                    <div className="rating">
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="single-testimoinal-item single-testimoinal-item--style2">
                    <div className="client-desig">
                      <div className="client-img bg-cover">
                        <img src={feedback4} alt="feedback-customer" />
                      </div>
                      <div className="client-name">
                        <h6>Emma Walker</h6>
                        <span>Account executive</span>
                      </div>
                    </div>
                    <div className="feedback">
                      "With LillyBot, I finally feel confident in my crypto
                      investments. The automation is seamless, and the profits
                      speak for themselves. Great platform!"
                    </div>
                    <div className="rating">
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="single-testimoinal-item">
                    <div className="client-desig">
                      <div className="client-img bg-cover">
                        <img src={feedback5} alt="feedback-customer" />
                      </div>
                      <div className="client-name">
                        <h6>Daniel Carter</h6>
                        <span>Software engineer</span>
                      </div>
                    </div>
                    <div className="feedback">
                      "Amazing results with LillyBot! The AI does all the heavy
                      lifting, and I’ve seen significant gains since I started
                      using it. Definitely worth the investment."
                    </div>
                    <div className="rating">
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial__pagination text-center mt-4"></div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- testimonial section end hare --> */}
    </div>
  );
};

export default Testimonials;
