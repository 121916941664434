import React, { useState, useMemo } from "react";
import { ArrowRight, ArrowLeft } from "react-bootstrap-icons";
import noDataImg from "../../assets/img/banner/no-data.png"; // Make sure the correct path to your image
import formatDate from "../../utils/formatDate";

const WalletReportTable = ({ walletData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Change as needed

  const handleFilter = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const filteredData = useMemo(() => {
    let filtered = walletData?.transactions || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (val) =>
          val.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          val.trtype?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          val.amount?.toString().includes(searchTerm) ||
          formatDate(val.created_on)?.toString().includes(searchTerm)
      );
    }
    if (sortConfig.key) {
      filtered = [...filtered].sort((a, b) => {
        const aKey = a[sortConfig.key] || "";
        const bKey = b[sortConfig.key] || "";

        if (aKey < bKey) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [walletData, searchTerm, sortConfig]);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredData.slice(start, end);
  }, [filteredData, currentPage, itemsPerPage]);

  // Calculate total pages for pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div
      className="pt-4 wow fadeInUp"
      data-wow-duration="1s"
      data-wow-delay=".2s"
    >
      <div className="card">
        <div className="row row-bordered g-0">
          <div className="col-12 p-2">
            <div className="card-header bg-dark">
              <div className="card-title text-center mb-0">
                <h5 className="m-0 me-2">Total Revenue</h5>
              </div>
            </div>
            <div className="p-3">
              {/* Filter Input */}
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleFilter}
                className="mb-3 form-control"
              />

              {/* Table Component */}
              <div className="table-responsive text-center">
                {paginatedData.length > 0 ? (
                  <table className="table table-dark table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" onClick={() => handleSort("type")}>
                          Transaction Memo / Hash
                        </th>
                        <th scope="col" onClick={() => handleSort("trtype")}>
                          Tr Type
                        </th>
                        <th scope="col" onClick={() => handleSort("amount")}>
                          Amount
                        </th>
                        <th
                          scope="col"
                          onClick={() => handleSort("created_on")}
                        >
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((val, index) => (
                        <tr key={index}>
                          <th scope="row">
                            {index + 1 + (currentPage - 1) * itemsPerPage}
                          </th>
                          <td className="text-light">
                            {val.hash && val.hash.length === 64 ? (
                              // Display hyperlink if hash length is 64
                              <a
                                href={`https://tronscan.io/#/transaction/${val.hash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {`${val.hash.substring(
                                  0,
                                  9
                                )}...${val.hash.substring(
                                  val.hash.length - 5
                                )}`}
                              </a>
                            ) : val.type ? (
                              val.type.length === 64 ? (
                                // Display hyperlink if type length is 64
                                <a
                                  href={`https://tronscan.io/#/transaction/${val.type}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`${val.type.substring(
                                    0,
                                    9
                                  )}...${val.type.substring(
                                    val.type.length - 5
                                  )}`}
                                </a>
                              ) : (
                                // Display type if not 64 characters
                                val.type
                              )
                            ) : (
                              // Display hash if not 64 characters
                              val.hash
                            )}
                          </td>
                          <td
                            className={
                              val.trtype === "debit"
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {val.trtype}
                          </td>
                          <td
                            className={`fw-bold ${
                              val.trtype === "debit"
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {val.trtype === "debit"
                              ? `-${val.amount}`
                              : `+${val.amount}`}
                          </td>
                          <td>{formatDate(val.created_on)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-data text-center rounded rounded-bottom">
                    <img src={noDataImg} alt="No Data" className="img-fluid" />
                  </div>
                )}
              </div>

              {/* Pagination Controls */}
              <div className="d-flex justify-content-between p-3">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                >
                  <ArrowLeft />
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                >
                  <ArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletReportTable;
