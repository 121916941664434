import api from "../../utils/api";
import { toast } from "react-toastify";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  EMAIL_VERIFIED,
  EMAIL_VERIFICATION_FAILED,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "../../utils/types"; // Correct import path

export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData, callback) => async (dispatch) => {
  try {
    const res = await api.post("/auth/register", formData);

    toast.success("Registration Successful");

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    localStorage.setItem("token", res.data.token); // Save token
    dispatch(loadUser());

    if (callback) callback(true);
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }

    dispatch({
      type: REGISTER_FAIL,
    });

    if (callback) callback(false);
  }
};

// Login Auth Action
export const login = (email, password, callback) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post("/auth/login", body);

    localStorage.setItem("token", res.data.token); // Save token
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());

    if (callback) callback(true);
  } catch (err) {
    const errors = err.response?.data?.errors;

    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
    if (callback) callback(false);
  }
};

export const forgotPassSendMail = (email, callback) => async () => {
  const body = { email };
  // toast.info(email);

  try {
    const res = await api.post("/auth/forgot-password-mail-verification", body);
    toast.success(res.data.msg);
    if (callback) callback(true);
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
    if (callback) callback(false);
  }
};

export const forgotPassword = (password, token, callback) => async () => {
  const body = { password, token };
  try {
    const res = await api.post("/auth/forgot-password", body);
    toast.success(res.data.msg);
    if (callback) callback(true);
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
    if (callback) callback(false);
  }
};

// Verify User Email
export const verifyUserEmail = (token, callback) => async (dispatch) => {
  try {
    const res = await api.post("/auth/email-verification", { token });
    // alert("Auth Action to Verify Email called with Token : ", token);
    dispatch({
      type: EMAIL_VERIFIED,
      payload: res.data,
    });

    console.log("Email verified successfully:", res.data);
    callback(true); // Verification succeeded
  } catch (err) {
    console.error("Email verification failed:", err.response || err.message);
    dispatch({
      type: EMAIL_VERIFICATION_FAILED,
    });
    callback(false); // Verification failed
  }
};

// Logout
export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch({ type: LOGOUT });
};

// Change Password Action
export const changePassword = (email, cPass, password) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, cPass, password });

    const response = await api.post("/auth/change-password", body, config);

    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: response.data.msg, // Success message from the server
    });

    toast.success("Password Change Successful");
    console.log("Password changed successfully:", response.data.msg);
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.errors
        ? error.response.data.errors[0].msg
        : error.message;

    dispatch({
      type: CHANGE_PASSWORD_FAILURE,
      payload: errorMessage,
    });

    // Display error message to the user
    toast.error(`Password Change Failed: ${errorMessage}`);
    console.error("Error changing password:", errorMessage);
  }
};
