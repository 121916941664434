import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";

import UserHeader from "./components/UserHeader";
import UserSubHeader from "./components/UserSubHeader";
import WalletData from "./components/WalletData";
import WalletFunctions from "./components/WalletFunctions";
import UserFooter from "./components/UserFooter";

import { getWalletData } from "../redux/actions/wallet";
import { getteamData } from "../redux/actions/team";

const Wallet = ({ getWalletData, getteamData, walletData, teamData }) => {
  useEffect(() => {
    if (typeof getWalletData === 'function') {
      getWalletData(); // Call the action to fetch wallet data
    } else {
      console.error("getWalletData is not a function");
    }
    getteamData();
  }, [getWalletData, getteamData]);

  
  return (
    <div>
      <UserHeader />
      <UserSubHeader pageTitle={"Wallet"} subTitle={"Deposit & Withdrawal Report"} />
      <WalletData walletData={walletData} teamData={teamData} />
      <WalletFunctions />
      <UserFooter />
    </div>
  );
};

Wallet.propTypes = {
  getWalletData: PropTypes.func.isRequired,
  getteamData: PropTypes.func.isRequired,
  walletData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  walletData: state.wallet.walletData,
  teamData: state.team.teamData
});

export default connect(mapStateToProps, { getWalletData, getteamData })(Wallet);
