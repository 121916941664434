import React, { useState } from "react";
import logo from "../../assets/img/logo/logo2.png";
import  { Link } from "react-router-dom";


const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div>
      {/* <!-- header section start --> */}

      <header className="header-1">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-2 col-sm-5 col-md-4 col-6">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Lilly Bot Logo" />
                </Link>
              </div>
            </div>

            {/* Desktop Menu */}
            <div className="col-lg-10 p-lg-0 d-none d-lg-flex align-items-center justify-content-end">
              <div className="menu-wrap">
                <div className="main-menu">
                  <ul>
                    <li className="menu-item">
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/services">Services</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="header-right-element text-white">
                <Link to="/signup" className="custom-btn custom-btn--style-2">
                  Get Started
                </Link>
              </div>
            </div>

            {/* Mobile Menu */}
            <div className="d-block d-lg-none col-sm-1 col-md-8 col-6">
              <div className="mobile-nav-wrap">
                <div id="hamburger" onClick={toggleMobileMenu}>
                  <div id="hamburger" onClick={toggleMobileMenu}>
                    <i className="fal fa-bars"></i>
                  </div>
                  {/* <!-- mobile menu - responsive menu  --> */}
                  <div
                    className={`mobile-nav ${isMobileMenuOpen ? "open" : ""}`}
                  >
                    <button
                      type="button"
                      className="close-nav"
                      onClick={toggleMobileMenu}
                    >
                      <i className="fal fa-times-circle"></i>
                    </button>
                    <nav className="sidebar-nav">
                      <ul className="metismenu" id="mobile-menu">
                        <li>
                          <Link to="/">
                            Homes
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">about</Link>
                        </li>
                        <li>
                          <Link to="/services">
                            services
                          </Link>
                        </li>
                        <li>
                          <Link className="has-arrow" to="/blogs">
                            Blogs
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>{" "}
                      </ul>
                      <div className="text-start mb-3">
                        <Link to="/signup" className="custom-btn">
                          Get Started
                        </Link>
                      </div>
                      <div className="text-start">
                        <Link to="/signin" className="custom-btn">
                          Signin
                        </Link>
                      </div>
                    </nav>
                  </div>
                </div>
                <div
                  className={`overlay ${isMobileMenuOpen ? "open" : ""}`}
                  onClick={toggleMobileMenu}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
