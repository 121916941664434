export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const UPDATE_COMMUNICATION_PREF = "UPDATE_COMMUNICATION_PREF";
export const UPDATE_TRANSACTION_PREF = "UPDATE_TRANSACTION_PREF";
export const GET_USER_PREF = "GET_USER_PREF";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const UPDATE_MOBILE_SUCCESS = "UPDATE_MOBILE_SUCCESS";
export const UPDATE_MOBILE_FAILURE = "UPDATE_MOBILE_FAILURE";

export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const EMAIL_VERIFICATION_FAILED = "EMAIL_VERIFICATION_FAILED";

export const FETCH_IMAGES_REQUEST = "FETCH_IMAGES_REQUEST";
export const FETCH_IMAGES_SUCCESS = "FETCH_IMAGES_SUCCESS";
export const FETCH_IMAGES_FAILURE = "FETCH_IMAGES_FAILURE";

// Action Types
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const GET_PACKAGES = "GET_PACKAGES";
export const GET_ACTIVE_PACKAGES = "GET_ACTIVE_PACKAGES";
export const ACTIVATE_PACKAGE = "ACTIVATE_PACKAGE";

export const GET_USDT_ADDRESS = "GET_USDT_ADDRESS";

export const GET_WALLET_DATA = "GET_WALLET_DATA";

// src/redux/types.js

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const GET_DASH_DATA = "GET_DASH_DATA";

export const WITHDRAW_REWARDS = "WITHDRAW_REWARDS";

export const CHECK_USDT_DEPOSIT = "CHECK_USDT_DEPOSIT";

export const GET_TEAM_DATA = "GET_TEAM_DATA";

export const GET_EARNING_DATA = "GET_EARNING_DATA";

export const CONTACT_FORM_SUBMIT_SUCCESS = "CONTACT_FORM_SUBMIT_SUCCESS";
export const CONTACT_FORM_SUBMIT_FAIL = "CONTACT_FORM_SUBMIT_FAIL";

export const GET_PASSIVE_DATA = "GET_PASSIVE_DATA";
