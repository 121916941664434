import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { login } from "../redux/actions/authActions";

import Header from "../home/components/Header";
import signin from "../assets/img/account/accont.png";

const Signin = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isRequest, setIsRequest] = useState(false);
  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsRequest(true);
    login(email, password, (success) => {
      if (!success) {
        setIsRequest(false);
      }
    });
  };

  if (isAuthenticated) {
    return <Navigate to="/user" />;
  }
  return (
    <div>
      <Header />

      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap  text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h1>Sign In</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sign In
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      {/* <!-- ==========signin Section start Here========== --> */}
      <div className="account section-padding">
        <div className=" container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="account__wrapper">
                <h3 className="title">Sign In</h3>
                <form className="account__form" onSubmit={onSubmit}>
                  <div className="form-floating mb-4">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                      name="email"
                      onChange={onChange}
                      required
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      onChange={onChange}
                      id="floatingPassword"
                      placeholder="Password"
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                      <div className="checkgroup">
                        <input type="checkbox" name="remember" id="remember" />
                        <label htmlFor="remember">Remember me</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    {!isRequest ? (
                      <button className="d-block custom-btn move-top">
                        <span>Sign In</span>
                      </button>
                    ) : (
                      <button
                        name=""
                        className="d-block custom-btn move-top"
                        disabled
                      >
                        Please Wait...
                      </button>
                    )}
                  </div>
                </form>
                <div className="account-bottom">
                  <p className="d-block cate pt-10">
                    Dont have an account? <Link to="/signup"> Sign Up</Link>
                  </p>
                  <p className="d-block cate pt-10">
                    Forgot Password? <Link to="/forgot-password">Click Here</Link>
                  </p>
                  {/* <p className="d-block cate pt-10">
                    Lost Password ?{" "}
                    <a href="/forgot-password"> Forgot Password</a>
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="account-img text-center">
                <img src={signin} alt="account vector" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ==========signup Section ends Here========== --> */}
    </div>
  );
};
Signin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Signin);
