import api from "../../utils/api";

import {
  CONTACT_FORM_SUBMIT_SUCCESS,
  CONTACT_FORM_SUBMIT_FAIL,
} from "../../utils/types";

import { toast } from "react-toastify";


export const submitContactForm = (formData) => async (dispatch) => {
  try {
    const response = await api.post("/contact", formData);
    dispatch({
      type: CONTACT_FORM_SUBMIT_SUCCESS,
      payload: response.data,
    });

    toast.success("Enquiery submitted successfully!");
  } catch (error) {
    dispatch({
      type: CONTACT_FORM_SUBMIT_FAIL,
      payload: error.response.data,
    });
    toast.error("Enquiery submission failed!");
  }
};
