import api from "../../utils/api";
import { toast } from "react-toastify";
import { GET_PACKAGES, ACTIVATE_PACKAGE, GET_ACTIVE_PACKAGES } from "../../utils/types";

export const getAllPackages = () => async (dispatch) => {
    try {
        const res = await api.get("/package");
        console.log("getPackages response:", res.data); // Add this to check the response
        dispatch({
            type: GET_PACKAGES,
            payload: res.data,
        });
    } catch (err) {
        const errors = err.response?.data?.errors;
        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }
    }
};

export const activatePackage = (packageId) => async (dispatch) => {
    try {
        const res = await api.post("/package/activate-package", { pkgId: packageId });
        dispatch({
            type: ACTIVATE_PACKAGE,
            payload: res.data,
        });
        toast.success("Package activated successfully");
    } catch (err) {
        const errors = err.response?.data?.errors;
        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }
    }
};

export const getActivateUserPackages = () => async (dispatch) => {
    try {
        const res = await api.get("/package/active-packages");
        console.log("getActivateUserPackages response:", res.data); // Add this to check the response
        dispatch({
            type: GET_ACTIVE_PACKAGES,
            payload: res.data,
        });
    } catch (err) {
        const errors = err.response?.data?.errors;
        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }
    }
};
