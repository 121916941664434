import api from "../../utils/api";
import { toast } from "react-toastify";
import { GET_DASH_DATA } from "../../utils/types";

export const getDashData = () => async (dispatch) => {
    try {
        const res = await api.get("/dashboard");
        dispatch({
            type: GET_DASH_DATA,
            payload: res.data,
        });
    } catch (err) {
        const errors = err.response?.data?.errors;
        if (errors) {
            errors.forEach((error) => toast.error(error.msg));
        }
    }
};