import React, { useEffect } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import UserHeader from "./UserHeader";
import UserSubHeader from "./UserSubHeader";
import noDataImg from "../../assets/img/banner/no-data.png";

import { getpassiveEarning } from "../../redux/actions/earning";

const PassiveIncome = ({ getpassiveEarning, passiveIncomeData }) => {
  useEffect(() => {
    getpassiveEarning();
  }, [getpassiveEarning]);

  // console.log(passiveIncomeData);

  return (
    <div>
      <UserHeader />
      <UserSubHeader pageTitle={"Revenue"} subTitle={"Passive Income"} />
      <section className="package-pricing-wrapper fix section-bg-4 section-padding">
        <div className="container">
          <div className="card">
            <div className="row row-bordered g-0">
              <div className="col-lg-12 p-2">
                <div className="card-header bg-dark">
                  <div className="card-title text-center mb-0">
                    <h5 className="m-0 me-2">Passive Income Reward</h5>
                  </div>
                </div>

                <div className="card-body text-center">
                  <div className="table-responsive">
                    {passiveIncomeData.length > 0 ? (
                      <table className="table table-bordered table-dark table-striped">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Passive Income Level</th>
                            <th scope="col">Target Team Business</th>
                            <th scope="col">Your Total Business</th>
                            <th scope="col">Initial Reward</th>
                            <th scope="col">Monthly Reward</th>
                            <th scope="col">Passive Income For Months</th>
                          </tr>
                        </thead>
                        <tbody>
                          {passiveIncomeData &&
                            passiveIncomeData.map((passIncome, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{passIncome.levels}</td>
                                <td>{passIncome.required_pairs}</td>
                                <td>0</td>
                                <td>${passIncome.first_reward}</td>
                                <td>${passIncome.achieved_monthly_reward}</td>
                                <td>{passIncome.valid_for_months}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="no-data text-center rounded rounded-bottom">
                        <img
                          src={noDataImg}
                          alt="No Data"
                          className="img-fluid"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

PassiveIncome.propTypes = {
  getpassiveEarning: PropTypes.func.isRequired,
  passiveIncomeData: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  passiveIncomeData: state.earning.passiveIncomeData,
});
export default connect(mapStateToProps, { getpassiveEarning })(PassiveIncome);
