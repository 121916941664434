import React from "react";

const Spinner = () => {
  return (
    <div>
      {/* <!-- preloader start here --> */}
      <div className="preloader">
        <div className="spinner"></div>
      </div>
      {/* <!-- preloader ending here --> */}
    </div>
  );
};

export default Spinner;
