import React from "react";
import shape5 from "../../assets/img/shape/5.svg";
import team1 from "../../assets/img/team/1.png";
import team3 from "../../assets/img/team/3.png";
import team4 from "../../assets/img/team/4.png";
import team5 from "../../assets/img/team/5.png";
import team6 from "../../assets/img/team/6.png";

const Team = () => {
  return (
    <div>
      {/* <!-- team section start hare --> */}

      <section className="team-section section-padding section-bg-4" id="team">
        <div className="container">
          <div
            className="nice-arrow-icon d-none d-lg-block wow fadeInDown"
            data-wow-duration="1.2s"
          >
            <img src={shape5} alt="3d bg shape" />
          </div>
          <div className="col-lg-8 col-xl-6 offset-xl-3 offset-lg-2 text-center">
            <div className="block-contents">
              <div
                className="section-title wow fadeInDown"
                data-wow-duration="1.2s"
              >
                <h2>Our Trading Team</h2>
              </div>
            </div>
          </div>

          <div className="team-slider swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="client-info client-info--style2">
                  <div className="team-img">
                    <img src={team1} alt="" />
                  </div>
                  <div className="client-name my-3">
                    <h4>Samuel Davis</h4>
                    <span>Account executive</span>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-info client-info--style2">
                  <div className="team-img">
                    <img src={team3} alt="" />
                  </div>
                  <div className="client-name my-3">
                    <h4>Harper Wilson</h4>
                    <span>Investment Analyst</span>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-info client-info--style2">
                  <div className="team-img">
                    <img src={team4} alt="" />
                  </div>
                  <div className="client-name my-3">
                    <h4>Noah Turner </h4>
                    <span>Research Analyst</span>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-info client-info--style2">
                  <div className="team-img">
                    <img src={team5} alt="" />
                  </div>
                  <div className="client-name my-3">
                    <h4>Olivia Johnson</h4>
                    <span>Risk Analyst</span>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="client-info client-info--style2">
                  <div className="team-img">
                    <img src={team6} alt="" />
                  </div>
                  <div className="client-name my-3">
                    <h4>Benjamin Mitchell</h4>
                    <span>Financial analyst</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="team__pagination text-center mt-4"></div>
        </div>
      </section>

      {/* <!-- team section end hare --> */}
    </div>
  );
};

export default Team;
