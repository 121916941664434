import React from "react";
import shape4 from "../../assets/img/shape/4.svg";
import features1 from "../../assets/img/feature/green/1.png";
import features2 from "../../assets/img/feature/green/2.png";
import features3 from "../../assets/img/feature/green/3.png";

const Features = () => {
  return (
    <div>
      {/* <!-- feature section start hare --> */}

      <section className="our-best-features-wrapper section-padding section-bg-4">
        <div className="container">
          <div
            className="nice-arrow-icon d-none d-lg-block wow fadeInDown"
            data-wow-duration="1.2s"
          >
            <img src={shape4} alt="3d bg shape" />
          </div>
          <div className="col-xl-8 offset-xl-2 text-center">
            <div className="block-contents">
              <div className="section-title">
                <h2>Key Trading Features for Success</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="features-card-item features-card-item--style2 style-1">
                <div className="icon">
                  <img src={features1} alt="Icon" />
                </div>
                <h3>Indicators for Trade Success</h3>
                <p>
                  Trade success indicators: price, market,risk management
                  ,volume and many more.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-card-item features-card-item--style2 style-1">
                <div className="icon">
                  <img src={features2} alt="Icon" />
                </div>
                <h3>Pro Tools for Risk Mastery</h3>
                <p>
                  Tools for mastering risk, such as analytical software, risk
                  assessment methods
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-card-item features-card-item--style2 style-1">
                <div className="icon">
                  <img src={features3} alt="Icon" />
                </div>
                <h3>Order Types for Success</h3>
                <p>
                  Different order types, including limit, stop, and market
                  orders, are vital for success
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- feature section start hare --> */}
    </div>
  );
};

export default Features;
