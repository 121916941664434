import { GET_DASH_DATA } from '../../utils/types';

const initialState = {
    dashData: {},
    loading: true,
    error: {}
};

function dashboardReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DASH_DATA:
            return {
                ...state,
                dashData: payload,
                loading: false
            };
        default:
            return state;
    }
}

export default dashboardReducer;

