import React from "react";
import error from "./assets/img/error/1.png";
import shape2 from "./assets/img/shape/2.svg";
import shape3 from "./assets/img/shape/3.svg";

const NotFound = () => {
  return (
    <div>
      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap  text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h1>404</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    404
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      <div className="error-404-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 text-center">
              <div className="error-content">
                <img src={error} alt="" />
                <h1 className="mt-5 mb-3">Oops! Page not found.</h1>
                <p>
                  "Oops! The page you're looking for doesn't exist. Let's get
                  you back on track!".
                </p>
                <a href="/" className="custom-btn mt-50">
                  Back To Homepage
                </a>
              </div>
              <div className="leaf">
                <img src={shape2} alt="" />
              </div>
              <div className="leaf-copy">
                <img src={shape3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
