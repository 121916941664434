import React from "react";
import faq1 from "../../assets/img/faq/2.png";

const CryptoApp = () => {
  return (
    <div>
      {/* crypto app section start hare */}

      <section className="content-block fix faq-wrapper section-padding section-bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-xl-5 mt-5 mt-lg-0 order-2 order-lg-1">
              <div className="block-contents">
                <div
                  className="section-title wow fadeInUp"
                  data-wow-duration="1s"
                >
                  <h2>Take full control of your crypto trades</h2>
                </div>
              </div>
              <div className="step-accordion">
                <div className="accordion" id="accordion">
                  <div
                    className="accordion-item wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".3s"
                  >
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq1"
                        aria-expanded="false"
                        aria-controls="faq1"
                      >
                        Register online
                      </button>
                    </h4>
                    <div
                      id="faq1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        You can easily activate the LillyBot auto trading
                        package from the tailored options as per your budget.{" "}
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item wow fadeInUp"
                    data-wow-duration="1.1s"
                    data-wow-delay=".6s"
                  >
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        Create an account easily
                      </button>
                    </h4>
                    <div
                      id="faq2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Open a secure account for yourself with your name and
                        necessary information that need
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item wow fadeInUp"
                    data-wow-duration="1.1s"
                    data-wow-delay=".9s"
                  >
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq3"
                        aria-expanded="false"
                        aria-controls="faq3"
                      >
                        Choose package & start daily profits
                      </button>
                    </h4>
                    <div
                      id="faq3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Crypto trades can be made easily, automated, and
                        profitable than human trading.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 offset-xl-1 pe-xl-3 col-12 order-1 order-lg-2">
              <div
                className="block-img ms-xl-5 wow fadeInRight"
                data-wow-duration="1.2s"
                data-wow-delay=".3s"
              >
                <img src={faq1} alt="faq img" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* crypto app section end hare */}
    </div>
  );
};

export default CryptoApp;
