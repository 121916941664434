import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./custom.module.css";
import userCard from "../assets/img/user/card/green/01.png";
import userCard2 from "../assets/img/user/card/green/02.png";
import userCard3 from "../assets/img/user/card/green/03.png";
import userCard4 from "../assets/img/user/card/green/04.png";
import UserHeader from "./components/UserHeader";
import UserSubHeader from "./components/UserSubHeader";

import noDataImg from "../assets/img/banner/no-data.png";

import { getEarningData } from "../redux/actions/earning";
const Earnings = ({ getEarningData, earningData }) => {
  useEffect(() => {
    getEarningData();
  }, [getEarningData]);
  const [reportType, setReportType] = useState("GR_RP");
  const growthAmtData = (rowData) => {
    // console.log(rowData);
    return (
      <span className="text-success fw-bold">+ ${rowData.growthAmount}</span>
    );
  };
  const pkgAmtData = (rowData) => {
    // console.log(rowData);
    return <span className="text-primary">${rowData.amount}</span>;
  };

  const pkgAmtPer = (rowData) => {
    // console.log(rowData);
    return (
      <span className="text-primary">
        ${((rowData.total_business * 5) / 100).toFixed(2)}
      </span>
    );
  };

  let daillyVerTrnx = earningData.growthTrnxs;
  let i = 1;
  daillyVerTrnx.forEach((element) => {
    element.srNo = i++;
  });
  let inc = 1;
  let drIncData = earningData.teammLevelReport;
  if (drIncData) {
    drIncData = drIncData[0] ? drIncData[0]?.users : [];
  }
  drIncData.forEach((element) => {
    element.srNo = inc++;
  });

  let teamRecurringReport = earningData.referralReport;
  teamRecurringReport = teamRecurringReport.filter(
    (el) => el.packageAmount > 0
  );
  let indX = 1;
  teamRecurringReport.forEach((element) => {
    element.srNo = indX++;
  });

  // console.log(teamRecurringReport);

  const calPer = (row) => {
    return (
      "$" + ((row.packageAmount * Number(row.percentage)) / 100).toFixed(2)
    );
  };
  const eliStatus = (row) => {
    return row.isEligible ? (
      <span className="text-success">Eligible</span>
    ) : (
      <span className="text-danger">Not Eligible</span>
    );
  };

  return (
    <div>
      <UserHeader />
      <UserSubHeader pageTitle={"Earnings"} subTitle={"Earnings"} />
      <section className="user-ditails-wrapper fix section-bg-5 section-padding-medium">
        <div className="container ">
          <div
            className="row section-padding-mediumwow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".2s"
          >
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper ">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Daily Growth Income
                        </div>
                        <p>${earningData.daillyEarnings}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard2} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Referral Income
                        </div>
                        <p>${earningData.totaldirRefIncome}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard3} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Recurring Growth
                        </div>
                        <p>
                          $
                          {Number(
                            earningData.totalRefIncome +
                              earningData.totaldirRefIncome
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard4} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Earning
                        </div>
                        <p>${earningData.totalEarning}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-padding-medium">
          <div className="container">
            <div
              className=" wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="mt-3">
                <div className="card">
                  <div className="card-header ">
                    <div className={styles.columns}>
                      <div className="col p-2">
                        <button
                          className="btn btn-success"
                          onClick={() => setReportType("GR_RP")}
                        >
                          Growth Income Report
                        </button>
                      </div>
                      <div className="col p-2">
                        <button
                          className="btn btn-dark"
                          onClick={() => setReportType("DI_RP")}
                        >
                          Direct Income Report
                        </button>
                      </div>
                      <div className="col p-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => setReportType("GI_RP")}
                        >
                          Level Income Report
                        </button>
                      </div>
                    </div>
                    <div className="card-title bg-dark text-light rounded p-3 text-center">
                      <h5 className="m-0 me-2">
                        {reportType === "GR_RP"
                          ? "Growth Income Report"
                          : reportType === "DI_RP"
                          ? "Direct Income Report"
                          : reportType === "GI_RP"
                          ? "Level Income Report"
                          : ""}
                      </h5>
                    </div>
                  </div>
                  {reportType === "GR_RP" ? (
                    <div className="card-body">
                      <div className="table-responsive table-striped rounded">
                        {daillyVerTrnx.length > 0 ? (
                          <DataTable
                            value={daillyVerTrnx}
                            paginator
                            rows={10}
                            sortField="price"
                            sortOrder={-1}
                            className="table table-dark "
                          >
                            <Column
                              field="srNo"
                              header="#"
                              sortable
                              className="p-3 m-2"
                            ></Column>
                            <Column
                              field="packageName"
                              header="Package Name"
                              sortable
                              className="p-3 m-2"
                            ></Column>
                            <Column
                              body={pkgAmtData}
                              header="Package Amount"
                              sortable
                              className="p-3 m-2"
                            ></Column>
                            <Column
                              header="Growth Amount"
                              body={growthAmtData}
                              className="p-3 m-2"
                              sortable
                            ></Column>
                            <Column
                              field="daysSinceCreation"
                              header="No Of Days"
                              sortable
                            ></Column>
                            <Column
                              field="activationTime"
                              header="Activation Date"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                            <Column
                              field="date"
                              header="Credit Date"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                          </DataTable>
                        ) : (
                          <div className="no-data text-center rounded rounded-bottom">
                            <img
                              src={noDataImg}
                              alt="No Data"
                              className="img-fluid"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : reportType === "DI_RP" ? (
                    <div className="card-body">
                      <div className="table-responsive table-striped rounded">
                        {drIncData.length > 0 ? (
                          <DataTable
                            value={drIncData}
                            paginator
                            rows={10}
                            sortField="price"
                            sortOrder={-1}
                            className="table table-dark"
                          >
                            <Column field="srNo" header="#" sortable></Column>
                            <Column
                              field="referral_id"
                              header="User Id"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                            <Column
                              field="total_business"
                              header="Total Package Amount"
                              sortable
                            ></Column>
                            <Column
                              body={pkgAmtPer}
                              header="Direct Income"
                              className="p-3"
                              sortable
                            ></Column>
                            <Column
                              field="first_package_activation_date"
                              header="Activation Date"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                          </DataTable>
                        ) : (
                          <div className="no-data text-center rounded rounded-bottom">
                            <img
                              src={noDataImg}
                              alt="No Data"
                              className="img-fluid"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : reportType === "GI_RP" ? (
                    <div className="card-body">
                      <div className="table-responsive table-striped rounded">
                        {teamRecurringReport.length > 0 ? (
                          <DataTable
                            value={teamRecurringReport}
                            paginator
                            rows={10}
                            sortField="price"
                            sortOrder={-1}
                            className="table table-dark"
                          >
                            <Column field="srNo" header="#" sortable></Column>
                            <Column
                              field="userId"
                              header="User Id"
                              sortable
                            ></Column>
                            <Column
                              field="packageName"
                              header="Package Name"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                            <Column
                              field="packageAmount"
                              header="Package Amount"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                            <Column
                              body={calPer}
                              header="Recurring Associate Income"
                              sortable
                            ></Column>
                            <Column
                              field="activationDate"
                              header="Activation Date"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                            <Column
                              field="date"
                              header="Growth Date"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                            <Column
                              body={eliStatus}
                              header="Eligiblity Status"
                              className="p-3 m-2"
                              sortable
                            ></Column>
                          </DataTable>
                        ) : (
                          <div className="no-data text-center rounded rounded-bottom">
                            <img
                              src={noDataImg}
                              alt="No Data"
                              className="img-fluid"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="no-data text-center rounded rounded-bottom">
                      <img
                        src={noDataImg}
                        alt="No Data"
                        className="img-fluid"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

Earnings.propTypes = {
  getEarningData: PropTypes.func.isRequired,
  earningData: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  earningData: state.earning.earningData,
});
export default connect(mapStateToProps, { getEarningData })(Earnings);
