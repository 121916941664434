import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "./Spinner";

const PrivateRoute = ({ auth: { isAuthenticated, loading } }) => {
  if (loading) return <Spinner />;
  if (!isAuthenticated) return <Navigate to="/signin" />;

  return <Outlet />;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
