import React from "react";
import UserHeader from "./components/UserHeader";
import UserSubHeader from "./components/UserSubHeader";
import JoiningPackages from "./components/JoiningPackages";

import UserFooter from "./components/UserFooter";

const Packages = () => {
  return (
    <div>
      <UserHeader />
      <UserSubHeader pageTitle={"Packages"} subTitle={"Joining Packages"} />      
      <JoiningPackages />
      <UserFooter />
    </div>
  );
};

export default Packages;
