import api from "../../utils/api";
import { toast } from "react-toastify";
import { GET_TEAM_DATA } from "../../utils/types";

export const getteamData = () => async (dispatch) => {
  try {
    const res = await api.get("/team/get-team-data");
    dispatch({
      type: GET_TEAM_DATA,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
  }
};
