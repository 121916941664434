import api from "../../utils/api";
import { toast } from "react-toastify";
import { GET_EARNING_DATA, GET_PASSIVE_DATA } from "../../utils/types";

export const getEarningData = () => async (dispatch) => {
  try {
    const res = await api.get("/earning/get-earnings");
    dispatch({
      type: GET_EARNING_DATA,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
  }
};

export const getpassiveEarning = () => async (dispatch) => {
  try {
    const res = await api.get("/passive-income");
    dispatch({
      type: GET_PASSIVE_DATA,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
  }
};
