import React from "react";
import UserHeader from "./components/UserHeader";
import UserSubHeader from "./components/UserSubHeader";
import UserFooter from "./components/UserFooter";
import ProfileData from "./components/ProfileData";
import ChangePassword from "./components/ChangePassword";

const Profile = () => {
  return (
    <div>
      <UserHeader />
      <UserSubHeader pageTitle={"Profile"} subTitle={"User Data"} />
      <ProfileData />

      <UserFooter />
    </div>
  );
};

export default Profile;
