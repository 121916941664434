import { combineReducers } from "redux";
import auth from "./auth"; // Import the reducer without curly braces
import image from "./image";
import product from "./product";
import packages from "./packages";
import wallet from "./wallet";
import dashboard from "./dashboard";
import team from "./team";
import earning from "./earning";
// Combine the reducers
export default combineReducers({
  auth: auth, // The key 'auth' represents the part of the state managed by authReducer
  images: image,
  products: product,
  packages: packages,
  wallet,
  dashboard,
  team,
  earning
});
