import React from "react";
import promo2 from "../../assets/img/promo/2.png";

const Promo = () => {
  return (
    <div>
      {/* <!-- promo-content-block section start hare --> */}

      <section className="promo-content-block fix section-padding section-bg-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 mt-5 mt-xl-0 order-2 order-xl-1">
              <div className="block-contents">
                <div className="section-title mb-4">
                  <h2 className="wow fadeInUp">
                    Expanding Trading Opportunities For Your Success!
                  </h2>
                  <p className="wow fadeInUp pt-15" data-wow-delay=".3s">
                    At our trading website, we are relentlessly focused on
                    driving growth and expanding our digital agency to ensure
                    your ultimate success in the dynamic world of trading.
                  </p>
                </div>
              </div>
              <div className="funfacts d-flex">
                <div
                  className="single-funfact single-funfact--style2 wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <h3>
                    <span>950</span>+
                  </h3>
                  <p>Happy clients</p>
                </div>
                <div
                  className="single-funfact wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h3>
                    <span>98</span>%
                  </h3>
                  <p>Customer satisfaction</p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 order-1 order-xl-2">
              <img src={promo2} alt="Clients" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- promo-content-block section end hare --> */}
    </div>
  );
};

export default Promo;
