import axios from "axios";
import store from "../redux/store";
import { LOGOUT } from "../utils/types";

// Determine if it's production or development
const isProduction = process.env.REACT_APP_NODE_ENV === "production";
const baseURL = isProduction
  ? process.env.REACT_APP_API_BASE_URL
  : process.env.REACT_APP_BASE_URL;

console.log(`Running in ${isProduction ? "production" : "development"} mode`);
console.log(`Base URL: ${baseURL}`);

// Create an axios instance
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

/*
  Intercept any error responses from the API
  and handle token expiration or unauthorized access.
*/
api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.status === 401) {
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);

export default api;
