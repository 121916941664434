import React, { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ErrorBoundary from "./home/ErrorBoundary";

import store from "./redux/store";
import { loadUser } from "./redux/actions/authActions";
import { LOGOUT } from "./utils/types";

import setAuthToken from "./utils/setAuthToken";

function App() {
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }

    const handleStorageChange = () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <AppRouter />
        <ToastContainer />
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
