import React, { useEffect, useState } from "react";
import axios from "axios";
import userCard from "../../assets/img/user/card/green/01.png";
import userCard2 from "../../assets/img/user/card/green/02.png";
import userCard3 from "../../assets/img/user/card/green/03.png";
import userCard4 from "../../assets/img/user/card/green/04.png";

const UsersDeatils = () => {
  const [homeStats, setHomeStats] = useState({
    userCount: 0,
    investSum: 0,
  });

  useEffect(() => {
    const fetchHomeStats = async () => {
      try {
        let response;
        // Check if it's production or development environment
        if (process.env.REACT_APP_NODE_ENV === "production") {
          response = await axios.get("https://lillybot.io:8443/api/home/homestats");
        } else {
          response = await axios.get("http://localhost:5000/api/home/homestats");
        }
  
        setHomeStats(response.data);
      } catch (error) {
        console.error("Error fetching home stats:", error);
      }
    };
  
    fetchHomeStats();
  }, []);

  return (
    <div>
      {/* <!-- user details section start here --> */}
      <section className="user-ditails-wrapper fix section-bg-5 section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="invest icon" />
                  </div>
                  <div className="user-card-content">
                    <h4>Total Invested</h4>
                    <p>${homeStats?.totalInvested || 0.00}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard3} alt="invest icon" />
                  </div>
                  <div className="user-card-content">
                    <h4>Total Withdrawal</h4>
                    <p>${homeStats?.totalWithdrawal || 0.00}</p> {/* Static data */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard2} alt="invest icon" />
                  </div>
                  <div className="user-card-content">
                    <h4>Today's Members</h4>
                    <p>{homeStats?.todayUserCount || 0}</p> 
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard4} alt="invest icon" />
                  </div>
                  <div className="user-card-content">
                    <h4>Total Members</h4>
                    <p>{homeStats?.userCount || 0}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- user data section start here --> */}
    </div>
  );
};

export default UsersDeatils;
