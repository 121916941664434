import { GET_EARNING_DATA, GET_PASSIVE_DATA } from "../../utils/types";

const initialState = {
  passiveIncomeData: [],
  earningData: {
    daillyEarnings: 0,
    totalRefIncome: 0,
    totaldirRefIncome: 0,
    totalEarning: 0,
    growthTrnxs: [],
    teammLevelReport: [],
    referralReport: [],
  },
  loading: true,
  error: {},
};

function earningReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EARNING_DATA:
      return {
        ...state,
        earningData: payload,
        loading: false,
        error: {},
      };
    case GET_PASSIVE_DATA:
      return {
        ...state,
        passiveIncomeData: payload,
        loading: false,
        error: {},
      };
    default:
      return state;
  }
}

export default earningReducer;
