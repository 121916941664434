import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import userCard from "../../assets/img/user/card/green/01.png";
import userCard2 from "../../assets/img/user/card/green/02.png";
import userCard3 from "../../assets/img/user/card/green/03.png";
import userCard4 from "../../assets/img/user/card/green/04.png";

import styles from "../custom.module.css";

import { getDashData } from "../../redux/actions/dashboard";
import { getWalletData } from "../../redux/actions/wallet";
import { getEarningData } from "../../redux/actions/earning";
import { getteamData } from "../../redux/actions/team";

import { getActivateUserPackages } from "../../redux/actions/package";
import formatDate from "../../utils/formatDate";

const UserData = ({
  getDashData,
  getWalletData,
  getEarningData,
  getteamData,
  getActivateUserPackages,
  dashData,
  walletData,
  earningData,
  teamData,
  activeUserPackages,
}) => {
  useEffect(() => {
    getDashData();
    getWalletData();
    getEarningData();
    getteamData();
    getActivateUserPackages();
  }, [
    getDashData,
    getWalletData,
    getEarningData,
    getteamData,
    getActivateUserPackages,
  ]);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getActivateUserPackages();
  }, [getActivateUserPackages]);

  console.log(activeUserPackages);

  const [pkgName, setPkgName] = useState("");
  const [pkgAmount, setPkgAmount] = useState("");
  const [dailyGrowth, setdailyGrowth] = useState("");
  const [dailyCapping, setdailyCapping] = useState("");
  const [activationDate, setactivationDate] = useState("");

  useEffect(() => {
    const userActivePkg = activeUserPackages; // Safe access to the first element
    // console.log("User Package", activeUserPackages.length);
    if (userActivePkg.length) {
      setPkgName(userActivePkg[userActivePkg.length - 1].Package.pkg_name);
      setPkgAmount(userActivePkg[userActivePkg.length - 1].Package.pkg_amount);

      setdailyGrowth(
        userActivePkg[userActivePkg.length - 1].Package.growth_per
      );
      setdailyCapping(
        userActivePkg[userActivePkg.length - 1].Package.per_day_income_capping
      );
      setactivationDate(
        formatDate(userActivePkg[userActivePkg.length - 1].created_on)
      );
      console.log(userActivePkg[userActivePkg.length - 1].created_on);
    } else {
      console.log("No active package found");
    }
  }, [activeUserPackages]);

  // console.log("Dashboard Data: ", dashData);
  // console.log("Wallet Data: ", walletData);
  // console.log("Earning Data: ", earningData);
  // console.log("Team Data : ", teamData);

  return (
    <div>
      {/* <!-- user ditails secion start hare --> */}

      <section className="user-ditails-wrapper fix section-bg-5 section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper ">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Investment
                        </div>
                        <p>${dashData?.totalPerchaseValue || "0.00"}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Earning
                        </div>
                        <p>${dashData?.totalSavings || "0.00"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard2} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Members
                        </div>
                        <p>{dashData.direct_referral_count || 0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Business
                        </div>
                        <p>{teamData.directBusiness || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard3} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Members
                        </div>
                        <p>{dashData.totalTeamSize || 0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Business
                        </div>
                        <p>${teamData.grandTotalBusiness || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard4} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Income
                        </div>
                        <p>${teamData?.directIncome || 0.0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Earning
                        </div>
                        <p>${teamData?.totalIncome || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper ">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Deposit
                        </div>
                        <p>${walletData?.totalCredit || "0.00"}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Withdrawal
                        </div>
                        <p>${walletData?.totalWithAmt || "0.00"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard2} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Growth Earning
                        </div>
                        <p>{dashData?.totalSavings || "0.00"}</p>
                      </div>
                      <div className="col mt-3">
                        <button
                          className="btn btn-primary"
                          onClick={() => (window.location.href = "/packages")}
                        >
                          Earnings
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard3} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-4">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <button
                          className="btn btn-success"
                          onClick={() => (window.location.href = "/wallet")}
                        >
                          Deposit
                        </button>
                      </div>
                      <div className="col">
                        <button
                          className="btn btn-danger"
                          onClick={() => (window.location.href = "/wallet")}
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-content mt-2">
                    <div className="small">User Name: {user?.name}</div>
                    <div className="mb-2">User ID: {user?.referral_id}</div>
                    {activeUserPackages.length > 0 ? (
                      <>
                        <div className={styles.fontMediumBold}>
                          Active Package: {pkgName} {pkgAmount}
                        </div>
                        <div className={styles.fontMediumBold}>
                          Activation Date: {activationDate}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="fs-6 text-bg-danger text-white text-center p-2 rounded">
                          No Active Package
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- user data secion start hare --> */}
    </div>
  );
};

UserData.propTypes = {
  getDashData: PropTypes.func.isRequired,
  getWalletData: PropTypes.func.isRequired,
  getEarningData: PropTypes.func.isRequired,
  getteamData: PropTypes.func.isRequired,
  getActivateUserPackages: PropTypes.func.isRequired,
  dashData: PropTypes.object.isRequired,
  walletData: PropTypes.object.isRequired,
  earningData: PropTypes.object.isRequired,
  teamData: PropTypes.object.isRequired,
  activeUserPackages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  dashData: state.dashboard.dashData,
  walletData: state.wallet.walletData,
  earningData: state.earning.earningData,
  teamData: state.team.teamData,
  activeUserPackages: state.packages.activeUserPackages,
});

export default connect(mapStateToProps, {
  getDashData,
  getWalletData,
  getEarningData,
  getteamData,
  getActivateUserPackages,
})(UserData);
