import React from "react";
import benefit2 from "../../assets/img/benifit/2.png";

const Token = () => {
  return (
    <div>
      {/* token section start hare  */}

      <section className="content-block section-padding section-bg-dark overflow-hidden">
        <div className="container">
          <div className="row align-items-center g-5">
            <div
              className="col-xl-6 col-lg-7 offset-xl-1 col-12 
        pe-xl-5"
            >
              <div className="block-contents ms-xl-3 mt-5 mt-lg-0">
                <div
                  className="section-title wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                >
                  <h2>Fair Profit Distribution for Traders</h2>
                </div>
                <p
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  LillyBot leverages sophisticated artificial intelligence
                  algorithms to analyze market trends, execute trades with
                  precision, and capitalize on profitable opportunities. With
                  real-time data processing and automated trading strategies,
                  LillyBot ensures you stay ahead of the curve, even while you
                  sleep.
                </p>
                <p
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  {" "}
                  Profits are distributed amoung members as per their Joining
                  Packages ratio.
                </p>
                <a href="javascrpt:" className="custom-btn mt-4" alt="">
                  Get Started
                </a>
              </div>
            </div>
            <div className="col-xl-5 pe-lg-0 col-lg-5 col-12">
              <div
                className="block-img wow fadeInLeft"
                data-wow-duration="1.1s"
              >
                <img src={benefit2} alt="benefit" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* token section end hare  */}
    </div>
  );
};

export default Token;
