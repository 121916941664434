import React from "react";
import benefit1 from "../../assets/img/benifit/1.png";

const About = () => {
  return (
    <div>
      {/* <!-- about section start hare --> */}

      <section className="content-block section-padding section-bg-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 pe-lg-0 col-lg-5">
              <div
                className="block-img wow fadeInLeft"
                data-wow-duration="1.1s"
              >
                <img src={benefit1} alt="benefit image of about section" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 offset-xl-1 ps-lg-5 pe-xl-5">
              <div className="block-contents ms-xl-3 mt-5 mt-lg-0">
                <div
                  className="section-title wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                >
                  <h2>Discover Our Trading Benefits</h2>
                </div>
                <p
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  LillyBot leverages sophisticated artificial intelligence
                  algorithms to analyze market trends, execute trades with
                  precision, and capitalize on profitable opportunities.
                </p>
                <p
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  {" "}
                  With real-time data processing and automated trading
                  strategies, LillyBot ensures you stay ahead of the curve, even
                  while you sleep.
                </p>
                <a href="/signup" className="custom-btn custom-btn--green mt-4">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- about section end hare --> */}
    </div>
  );
};

export default About;
