import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";

import styles from "../custom.module.css";

import noDataImg from "../../assets/img/banner/no-data.png";

import {
  getAllPackages,
  activatePackage,
  getActivateUserPackages,
} from "../../redux/actions/package";

import { getWalletData } from "../../redux/actions/wallet";
import formatDate from "../../utils/formatDate";

import icon1 from "../../assets/img/price/icon/1.png";
const JoiningPackages = ({
  getAllPackages,
  getWalletData,
  activatePackage,
  allPackages,
  getActivateUserPackages,
  activeUserPackages,
  walletData,
}) => {
  useEffect(() => {
    getAllPackages();
    getWalletData();
    getActivateUserPackages();
  }, [getActivateUserPackages, getAllPackages, getWalletData]);

  console.log("Wallet Data : ", walletData);

  const navigate = useNavigate();

  const activatePackageHandler = async (pkgId, pkgName, pkgParcentage) => {
    Swal.fire({
      title: `Are you sure ? you want activate ${pkgName} Membership Package?`,
      text: `You shall get daily growth of ${pkgParcentage}% !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        activatePackage(pkgId);
      }
    });
  };
  return (
    <div>
      <section className="package-pricing-wrapper fix section-bg-4 section-padding">
        <div className="container">
          <div className="col-lg-8 ps-xl-5 pe-xl-5 offset-lg-2 text-center">
            <div className="block-contents">
              <div className="section-title">
                <h2 className="wow fadeInUp">
                  Tailored Plans to Fit Your Financial Needs
                </h2>
                <p className="wow fadeInUp pt-4" data-wow-delay=".3s">
                  LillyBot is advanced AI based auto crypto trading bot
                  spicially developed for LillyBot members.
                </p>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-success"
                  onClick={() => window.open("/assets/download/LillyBot.io.pdf", "_blank")}
                >
                  Download Plan PPT
                </button>
              </div>

            </div>
          </div>

          <div className="row justify-content-center">
            {allPackages &&
              allPackages.map((pkg) => {
                // Split the features string into an array
                // const features = pkg.pkg_info ? pkg.pkg_info.split("\r\n") : [];
                const features = pkg.pkg_info.split("\n").filter(Boolean);

                return (
                  <div className="col-xl-3 col-lg-4 col-md-6" key={pkg.id}>
                    <div
                      className="single-pricing-package single-pricing-package--style2 wow fadeInUp"
                      data-wow-duration="1.1s"
                    >
                      <div className={styles.priceListRow}>
                        <div className="pricing-head">
                          <div className="pricing-name">
                            <h4>{pkg.pkg_name}</h4>
                          </div>
                          <div className="pricing-vale d-flex">
                            <h3>
                              $<span>{pkg.pkg_amount}</span>
                            </h3>
                            <p>/ {pkg.growth_per}</p>
                          </div>
                        </div>
                        <div className="features-list">
                          <ul>
                            {features.map((feature, index) => (
                              <li key={index}>
                                <img src={icon1} alt="right-arrow" />
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="row text-center p-3">
                          {activeUserPackages &&
                          activeUserPackages.some(
                            (actPkg) => actPkg.pkg_id === pkg.package_id
                          ) ? (
                            <span className={styles.activePkg}>
                              Active Package
                            </span>
                          ) : (
                            <button
                              className={
                                walletData.walletBalance < pkg.pkg_amount
                                  ? styles.nonActiveButton
                                  : styles.activationButton
                              }
                              onClick={() =>
                                walletData.walletBalance >= pkg.pkg_amount &&
                                activatePackageHandler(
                                  pkg.package_id,
                                  pkg.pkg_name,
                                  pkg.growth_per
                                )
                              }
                            >
                              {walletData.walletBalance < pkg.pkg_amount
                                ? "Low Wallet Balance"
                                : "Activate"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <hr />
        </div>
        <div className="section-padding">
          <div className="container">
            <div className="card">
              <div className="row row-bordered g-0">
                <div className="col-lg-12 p-2">
                  <div className="card-header bg-dark">
                    <div className="card-title text-center mb-0">
                      <h5 className="m-0 me-2">Active Packages</h5>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      {activeUserPackages.length > 0 ? (
                        <table className="table table-bordered table-dark table-striped">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Package Name</th>
                              <th scope="col">Package Amount</th>
                              <th scope="col">Growth %</th>
                              <th scope="col">Activated ON</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeUserPackages &&
                              activeUserPackages.map((activePkg, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{activePkg.Package.pkg_name}</td>
                                  <td>{activePkg.Package.pkg_amount}</td>
                                  <td>{activePkg.Package.growth_per} %</td>
                                  <td>{formatDate(activePkg.created_on)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="no-data text-center rounded rounded-bottom">
                          <img
                            src={noDataImg}
                            alt="No Data"
                            className="img-fluid"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

JoiningPackages.propTypes = {
  getAllPackages: PropTypes.func.isRequired,
  getWalletData: PropTypes.func.isRequired,
  activatePackage: PropTypes.func.isRequired,
  allPackages: PropTypes.array.isRequired,
  getActivateUserPackages: PropTypes.func.isRequired,
  activeUserPackages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  allPackages: state.packages.allPackages,
  activeUserPackages: state.packages.activeUserPackages,
  walletData: state.wallet.walletData,
});

export default connect(mapStateToProps, {
  getAllPackages,
  getWalletData,
  activatePackage,
  getActivateUserPackages,
})(JoiningPackages);
