import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { register } from "../redux/actions/authActions";
import signup from "../assets/img/account/signup.png";
import Header from "../home/components/Header";

const Signup = ({ register }) => {
  const navigate = useNavigate();
  const { refid } = useParams(); // Extract the referral ID from the URL

  console.log("Referred By :", refid);

  const [referralId, setReferralId] = useState(refid || "LB101010"); // Set the referral ID, default if not present
  const [isRequest, setIsRequest] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_pass: "",
    referred_by: referralId,
  });

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (refid) {
      setReferralId(refid);
      setFormData((prevFormData) => ({
        ...prevFormData,
        referred_by: refid,
      }));
    }
  }, [refid]);

  const { name, email, password, confirm_pass } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsRequest(true);
    if (password !== confirm_pass) {
      setIsRequest(false);
      toast.error("Passwords do not match");
    } else {
      register(
        { name, email, password, referred_by: formData.referred_by },
        (success) => {
          if (success) {
            setRedirect(true);
          } else {
            setIsRequest(false);
          }
        }
      );
    }
  };

  if (redirect) {
    return <Navigate to="/verify-email" />;
  }

  return (
    <div>
      <Header />
      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap  text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h1>Sign Up</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sign Up
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      {/* <!-- ==========signup Section start Here========== --> */}
      <div className="account section-padding">
        <div className=" container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="account__wrapper">
                <h3 className="title">Sign Up</h3>
                <form className="account__form" onSubmit={onSubmit}>
                  <div className="form-floating mb-4">
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Please Enter Your Name"
                      value={name}
                      onChange={onChange}
                    />
                    <label htmlFor="floatingInput-user">Name</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Please Enter Valid Email Address"
                      value={email}
                      onChange={onChange}
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      name="password"
                      placeholder="Please Enter Strong Alpha Numeric Password"
                      value={password}
                      onChange={onChange}
                      className="form-control"
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      name="confirm_pass"
                      placeholder="Confirm Password as per above field"
                      value={confirm_pass}
                      onChange={onChange}
                      className="form-control"
                    />
                    <label htmlFor="floatingPassword2">Confirm Password</label>
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                      <div className="checkgroup">
                        <input type="checkbox" name="remember" id="remember" />
                        <label htmlFor="remember">Accept Terms & Policy</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    {!isRequest ? (
                      <button
                        name="Register"
                        type="submit"
                        className="d-block custom-btn move-top"
                      >
                        Sign Up
                      </button>
                    ) : (
                      <button
                        name=""
                        className="d-block custom-btn move-top"
                        disabled
                      >
                        Please Wait...
                      </button>
                    )}
                  </div>
                </form>
                <div className="account-bottom">
                  <p className="d-block cate pt-10">
                    {" "}
                    Have an Account? <a href="/signin"> Sign in</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="account-img text-center">
                <img src={signup} alt="account vector" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ==========signup Section ends Here========== --> */}
    </div>
  );
};

Signup.propTypes = {
  register: PropTypes.func.isRequired,
};

export default connect(null, { register })(Signup);
