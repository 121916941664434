import React, { useState, useMemo } from "react";
import { ArrowRight, ArrowLeft } from "react-bootstrap-icons";

import noDataImg from "../../../assets/img/banner/no-data.png";

const DirectTeamReport = ({ teamData }) => {
  const directTeamData =
    teamData?.teamLevelReport?.length > 0 && teamData.teamLevelReport[0]?.users
      ? teamData.teamLevelReport[0].users
      : [];

  let i = 1;
  directTeamData.forEach((element) => {
    element.level = 1;
    element.idx = i++;
    element.first_package_activation_date =
      element.first_package_activation_date === ""
        ? "Not Active"
        : element.first_package_activation_date;
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Change as needed

  const handleFilter = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  // console.log(teamData);

  const filteredData = useMemo(() => {
    let filtered = directTeamData || [];
    if (searchTerm) {
      filtered = filtered.filter(
        (val) =>
          val.referral_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          "" ||
          val.total_business?.toString().includes(searchTerm) ||
          "" ||
          val.user_created_on?.toString().includes(searchTerm) ||
          "" ||
          val.first_package_activation_date?.toString().includes(searchTerm) ||
          ""
      );
    }
    if (sortConfig.key) {
      filtered = [...filtered].sort((a, b) => {
        const aKey = a[sortConfig.key] || "";
        const bKey = b[sortConfig.key] || "";

        if (aKey < bKey) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [directTeamData, searchTerm, sortConfig]);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredData.slice(start, end);
  }, [filteredData, currentPage, itemsPerPage]);

  // Calculate total pages for pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  return (
    <div className="section-padding-medium">
      <div className="card">
        <div className="card-header">
          <div className="card-title bg-dark text-light text-center rounded p-3">
            <h5 className="m-0 me-2">Direct Members Report</h5>
          </div>
        </div>
        <div className="p-3">
          {/* Filter Input */}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleFilter}
            className="mb-3 form-control"
          />

          {/* Table Component */}
          <div className="table-responsive">
            {paginatedData.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" onClick={() => handleSort("referral_id")}>
                      User ID
                    </th>
                    <th
                      scope="col"
                      onClick={() => handleSort("total_business")}
                    >
                      Total Pkg Amount
                    </th>
                    <th
                      scope="col"
                      onClick={() => handleSort("user_created_on")}
                    >
                      Registration Date
                    </th>
                    <th
                      scope="col"
                      onClick={() =>
                        handleSort("first_package_activation_date")
                      }
                    >
                      Activation Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.map((val, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">
                          {index + 1 + (currentPage - 1) * itemsPerPage}
                        </th>
                        <td>{val.referral_id}</td>
                        <td>${val.total_business}</td>
                        <td>{val.user_created_on}</td>
                        <td>{val.first_package_activation_date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="no-data text-center rounded rounded-bottom">
                <img src={noDataImg} alt="No Data" className="img-fluid" />
              </div>
            )}
          </div>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-between p-3">
            <button
              className="btn btn-primary"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              <ArrowLeft />
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-primary"
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectTeamReport;
