import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Index from "../home/Index";
import Signup from "../frontend/Signup";
import Signin from "../frontend/Signin";
import ForgotPassMail from "../frontend/ForgotPassMail";
import NotFound from "../NotFound";
import About from "../home/AboutUs";
import Services from "../home/Services";
import BlogPost from "../home/BlogPost";
import ContactUs from "../home/ContactUs";
import EmailVerification from "../frontend/EmailVerification";
import VerifyEmail from "../frontend/VerifyEmail";
import InvalidLink from "../frontend/InvalidLink";
import Dashboard from "../dashboard/Dashboard";
import PrivateRoute from "./PrivateRoute";
import Forgotpassword from "../frontend/Forgotpassword";
import Packages from "../dashboard/Packages";
import Wallet from "../dashboard/Wallet";
import Team from "../dashboard/Team";
import Profile from "../dashboard/Profile";
import Earnings from "../dashboard/Earnings";
import PassiveIncome from "../dashboard/components/PassiveIncome";
// import Forgotpassword from "../frontend/Forgotpassword";

// import PrivateRoute from "../routes/PrivateRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/blogs",
    element: <BlogPost />,
  },
  {
    path: "/contact",
    element: <ContactUs />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/signup/:refid",
    element: <Signup />,
  },
  {
    path: "/verify-email",
    element: <EmailVerification />, // Corrected component name
  },
  {
    path: "/email-verification",
    element: <VerifyEmail />,
  },
  {
    path: "/email-verification-failed",
    element: <InvalidLink />,
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassMail />,
  },

  {
    path: "/forgot-password-verification/:authtoken",
    element: <Forgotpassword />,
  },

  {
    path: "/*",
    element: <NotFound />,
  },

  {
    path: "/user",
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
    ],
  },

  {
    path: "/packages",
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Packages />,
      },
    ],
  },

  {
    path: "/wallet",
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Wallet />,
      },
    ],
  },

  {
    path: "/profile",
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Profile />,
      },
    ],
  },

  {
    path: "/team",
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Team />,
      },
    ],
  },
  {
    path: "/revenue",
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Earnings />,
      },
      {
        path: "passive-income",
        element: <PassiveIncome />,
      },
    ],
  },
]);

function AppRouter() {
  return <RouterProvider router={router} />;
}

export default AppRouter;
