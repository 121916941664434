import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

const BlogPost = () => {
  return (
    <div>
      {/* <!-- header section start hare --> */}
      <Header />
      {/* <!-- header section end hare --> */}

      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap bg-cover text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3 text-center text-white">
              <div className="page-heading">
                <h1>Blog</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Blog
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      {/* Blog Section */}

      <div className="blog-details-wrapper section-padding mtm-30">
        <div className="container">
          <div className="row justify-content-center g-5">
            <div className="col-xl-8 col-12 pe-xl-5">
              <div className="blog-top">
                <img
                  src="assets/img/blog/details/single-6.png"
                  alt="crypto trading image"
                />

                <div className="post-meta mt-2 d-flex">
                  <div className="post-cat me-4">
                    <a href="blog-three-col.html">Admin</a>
                  </div>
                  <div className="post-date me-4">
                    <span style={{ fontSize: "16px" }}>Dec. 13, 2024</span>
                  </div>
                  <div className="post-comment">
                    <a>Comment (5)</a>
                  </div>
                </div>

                <h3 className="pt-md-4">
                  Unlocking the Potential: A Comprehensive Guide to Crypto
                  Trading Strategie
                </h3>
                <p className="mt-4">
                  Step into the future of finance with our detailed guide to
                  cryptocurrency trading. "Unlocking the Potential" serves as an
                  essential resource for traders at all levels, providing clear
                  explanations, actionable strategies, and insights from
                  industry experts.{" "}
                </p>
                <p className="mt-4 mb-4">
                  Learn how to navigate the complexities of the crypto market,
                  make informed decisions, and optimize your trading
                  performance. This guide is your key to unlocking the full
                  potential of crypto trading and achieving your financial
                  goals.
                </p>

                <a href="blog-details.html" className="custom-btn">
                  Read more
                </a>

                <hr className="my-4" />
              </div>

              <div className="row g-4">
                <div className="col-md-6 col-xl-6 col-12">
                  <div className="blog-inner wow fadeInUp">
                    <div className="blog-thumb">
                      <img src="assets/img/blog/1.jpg" alt="blog-img" />
                      <div className="post-meta-top">
                        <div className="post-author">
                          <div className="post-author-thumb">
                            <img
                              src="assets/img/blog/author/1.png"
                              alt="author"
                            />
                          </div>
                          <div className="post-author-name">Christopher</div>
                        </div>
                        <div className="post-date">
                          <span>June. 17, 2024</span>
                        </div>
                      </div>
                    </div>
                    <div className="contents">
                      <div className="post-meta d-flex">
                        <div className="post-cat">
                          <a href="blog-three-col.html">Trends</a>
                        </div>

                        <div className="post-comment">
                          <a href="blog-three-col.html">Comments (5)</a>
                        </div>
                      </div>
                      <h4>
                        <a href="blog-details.html">
                          Trending Trading To Empower Strategies, Make
                          Decisions.
                        </a>
                      </h4>

                      <a href="blog-details.html" className="read-more-link">
                        read more <i className="icon-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xl-6 col-12">
                  <div className="blog-inner wow fadeInUp" data-wow-delay=".4s">
                    <div className="blog-thumb">
                      <img src="assets/img/blog/4.jpg" alt="blog-img" />
                      <div className="post-meta-top">
                        <div className="post-author">
                          <div className="post-author-thumb">
                            <img
                              src="assets/img/blog/author/4.png"
                              alt="author"
                            />
                          </div>
                          <div className="post-author-name">Florence</div>
                        </div>
                        <div className="post-date">
                          <span>May. 11, 2024</span>
                        </div>
                      </div>
                    </div>
                    <div className="contents">
                      <div className="post-meta d-flex">
                        <div className="post-cat">
                          <a href="blog-three-col.html">Market</a>
                        </div>
                        <div className="post-comment">
                          <a href="blog-three-col.html">Comments (6)</a>
                        </div>
                      </div>
                      <h4>
                        <a href="blog-details.html">
                          Marketing Trading Strategies Boost Your Success
                          Potential
                        </a>
                      </h4>
                      <a href="blog-details.html" className="read-more-link">
                        read more <i className="icon-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xl-6 col-12">
                  <div className="blog-inner wow fadeInUp" data-wow-delay=".4s">
                    <div className="blog-thumb">
                      <img src="assets/img/blog/5.jpg" alt="blog-img" />
                      <div className="post-meta-top">
                        <div className="post-author">
                          <div className="post-author-thumb">
                            <img
                              src="assets/img/blog/author/5.png"
                              alt="author"
                            />
                          </div>
                          <div className="post-author-name">Elizabeth</div>
                        </div>
                        <div className="post-date">
                          <span>Feb. 16, 2024</span>
                        </div>
                      </div>
                    </div>
                    <div className="contents">
                      <div className="post-meta d-flex">
                        <div className="post-cat">
                          <a href="blog-three-col.html">Resources</a>
                        </div>
                        <div className="post-comment">
                          <a href="blog-three-col.html">Comments (4)</a>
                        </div>
                      </div>
                      <h4>
                        <a href="blog-details.html">
                          Powerful Trading Strategies To Boost Your Success
                          Potential
                        </a>
                      </h4>
                      <a href="blog-details.html" className="read-more-link">
                        read more <i className="icon-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xl-6 col-12">
                  <div className="blog-inner wow fadeInUp" data-wow-delay=".4s">
                    <div className="blog-thumb">
                      <img src="assets/img/blog/6.jpg" alt="blog-img" />
                      <div className="post-meta-top">
                        <div className="post-author">
                          <div className="post-author-thumb">
                            <img
                              src="assets/img/blog/author/6.png"
                              alt="author"
                            />
                          </div>
                          <div className="post-author-name">Frederick</div>
                        </div>
                        <div className="post-date">
                          <span>Jan. 7, 2024</span>
                        </div>
                      </div>
                    </div>
                    <div className="contents">
                      <div className="post-meta d-flex">
                        <div className="post-cat">
                          <a href="blog-three-col.html">Perspectives</a>
                        </div>
                        <div className="post-comment">
                          <a href="blog-three-col.html">Comments (8)</a>
                        </div>
                      </div>
                      <h4>
                        <a href="blog-details.html">
                          Perspectives Trading Strategies To Boost Your Success.
                        </a>
                      </h4>
                      <a href="blog-details.html" className="read-more-link">
                        read more <i className="icon-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blog-pagination">
                <nav className="" aria-label="Blog pagination">
                  <ul className="pagination justify-content-center">
                    <li className="page-item disabled">
                      <a className="page-link">Previous</a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item active" aria-current="page">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-xl-4 col-md-8 col-12">
              <div className="sidebar__wrapper">
                <div className="sidebar__widget sidebar__widget-theme-bg mb-30">
                  <div className="sidebar__widget-content">
                    <div className="sidebar__search">
                      <form action="#">
                        <div className="sidebar__search-input-2">
                          <input type="text" placeholder="Search here" />
                          <button type="submit">
                            <i className="far fa-search"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="sidebar__widget mb-30">
                  <h3 className="sidebar__widget-title">Catagories</h3>
                  <div className="sidebar__widget-content">
                    <ul>
                      <li>
                        <a href="blog-three-col.html">
                          Crypto Trading
                          <span>
                            <i className="icon-arrow-right"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="active" href="blog-three-col.html">
                          Day Trading
                          <span>
                            <i className="icon-arrow-right"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="blog-three-col.html">
                          Option Trading
                          <span>
                            <i className="icon-arrow-right"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="blog-three-col.html">
                          Stock Trading
                          <span>
                            <i className="icon-arrow-right"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="blog-three-col.html">
                          Forex Investment
                          <span>
                            <i className="icon-arrow-right"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="blog-three-col.html">
                          TradePro Template
                          <span>
                            <i className="icon-arrow-right"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="sidebar__widget mb-30">
                  <h3 className="sidebar__widget-title">Our Latest Post</h3>
                  <div className="sidebar__widget-content">
                    <div className="sidebar__post">
                      <div className="rc__post mb-25">
                        <div className="rc__post-thumb mr-20">
                          <a href="blog-details.html">
                            <img
                              src="assets/img/blog/details/single/1.png"
                              alt="Blog Image"
                            />
                          </a>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>
                              <i className="far fa-clock"></i>
                              November 21, 2024
                            </span>
                          </div>
                          <h3 className="rc__post-title">
                            <a href="blog-details.html">
                              Trending Trading To Empower Strategies
                            </a>
                          </h3>
                        </div>
                      </div>
                      <div className="rc__post mb-25">
                        <div className="rc__post-thumb mr-20">
                          <a href="blog-details.html">
                            <img
                              src="assets/img/blog/details/single/2.png"
                              alt="Blog Image"
                            />
                          </a>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>
                              <i className="far fa-clock"></i>
                              November 21, 2024
                            </span>
                          </div>
                          <h3 className="rc__post-title">
                            <a href="blog-details.html">
                              Marketing Trading Strategies Boost Your Success
                            </a>
                          </h3>
                        </div>
                      </div>
                      <div className="rc__post">
                        <div className="rc__post-thumb mr-20">
                          <a href="blog-details.html">
                            <img
                              src="assets/img/blog/details/single/3.png"
                              alt="Blog Image"
                            />
                          </a>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>
                              <i className="far fa-clock"></i>
                              November 21, 2024
                            </span>
                          </div>
                          <h3 className="rc__post-title">
                            <a href="blog-details.html">
                              TradePro - Trading Template To Boost Your Success.
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar__widget mb-30">
                  <h3 className="sidebar__widget-title">Archives List</h3>
                  <div className="sidebar__widget-content">
                    <ul>
                      <li>
                        <a href="#">
                          July 2024<span>(10)</span>
                        </a>
                      </li>
                      <li className="active">
                        <a href="#">
                          August 2024<span>(05)</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          March 2024<span>(10)</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          April 2024<span>(20)</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          June 2024<span>(13)</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          January 2024<span>(05)</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="sidebar__widget mb-30">
                  <h3 className="sidebar__widget-title">Archive Dropdown</h3>
                  <div className="sidebar__widget-content">
                    <select className="form-select" data-bs-theme="dark">
                      <option selected>Select Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                    </select>
                  </div>
                </div>

                <div className="sidebar__widget mb-30">
                  <h3 className="sidebar__widget-title">Tags</h3>
                  <div className="sidebar__widget-content">
                    <div className="tagcloud">
                      <a href="#">trading</a>
                      <a href="#">tradepro</a>
                      <a href="#">ETF</a>
                      <a href="#">Blockchain</a>
                      <a href="#">Crypto</a>
                      <a href="#">Bitcoin</a>
                      <a href="#">Stock</a>
                      <a href="#">Day Trading</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="newsletter-subscribe-widgets text-white">
                <div className="wid-title">
                  <h5>Join Us!</h5>
                </div>
                <p>
                  Subscribe our newsletter and stay up to date about the company
                </p>
                <div className="newsletter-subscribe">
                  <form action="#">
                    <input type="email" placeholder="Enter your email" />
                    <button type="submit" className="submit-btn">
                      Subscribe Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blog Section End */}

      <Footer />
    </div>
  );
};

export default BlogPost;
