import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import Header from "../home/components/Header";
import { forgotPassword } from "../redux/actions/authActions";
import forgetPassImg from "../assets/img/account/forgot-pass.png";

const Forgotpassword = ({ forgotPassword }) => {
  const { authtoken } = useParams();
  const navigate = useNavigate();
  const [isRequest, setIsRequest] = useState(false);

  const [formData, setFormData] = useState({ password: "", password2: "" });
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const { password, password2 } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsRequest(true);

    if (password !== password2) {
      setIsRequest(false);
      toast.error("Passwords do not match");
    } else {
      const token = authtoken;
      forgotPassword(password, token, (success) => {
        if (success) {
          navigate('/signin');
        } else {
          setIsRequest(false);
          toast.error("Error resetting password");
        }
      });
    }
  };
  return (
    <div>
      <Header />
      <div className="page-banner-wrap  text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h1>Reset Password</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Reset Password
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="account section-padding">
        <div className=" container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="account__wrapper">
                <h3 className="title">Reset Password?</h3>
                  <>
                    <form className="account__form" onSubmit={onSubmit}>
                      <div className="form-floating mb-4">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="floatingInput"
                          onChange={onChange}
                          placeholder="Password"
                        />
                        <label htmlFor="floatingInput">Enter New Password</label>

                      </div>
                      <div className="form-floating mb-4">
                        <input
                          type="password"
                          name="password2"
                          className="form-control"
                          id="floatingInput2"
                          onChange={onChange}
                          placeholder="Re Enter Password"
                        />
                        <label htmlFor="floatingInput">Re Enter Password</label>
                      </div>
                      <div className="form-group">
                        {!isRequest ? (
                          <button
                            name="submit"
                            type="submit"
                            className="d-block custom-btn move-top"
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            name=""
                            className="d-block custom-btn move-top"
                            disabled
                          >
                            Please Wait...
                          </button>
                        )}
                      </div>
                    </form>
                  </>
                <div className="account-bottom">
                  <p className="d-block cate pt-10">
                    Have an account? <a href="/signin"> Sign In</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="account-img text-center">
                <img
                  src={forgetPassImg}
                  alt="accountVector"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Forgotpassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
};

export default connect(null, { forgotPassword })(Forgotpassword);
