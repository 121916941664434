import React from "react";
import blog1 from "../../assets/img/blog/1.jpg";
import blog2 from "../../assets/img/blog/2.jpg";
import blog3 from "../../assets/img/blog/3.jpg";
import shape2 from "../../assets/img/shape/2.svg";

const Blog = () => {
  return (
    <div>
      {/* blog section start hare */}

      <section className=" blog-wrapper section-padding fix">
        <div className="container">
          <div
            className="nice-arrow-icon d-none d-lg-block wow fadeInDown"
            data-wow-duration="1.2s"
          >
            <img src={shape2} alt="3d bg shape" />
          </div>
          <div className="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
            <div className="block-contents">
              <div
                className="section-title wow fadeInUp"
                data-wow-duration="1.2s"
              >
                <h2>Dive into Our Crypto Blog</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xl-4 col-12">
              <div className="blog-inner wow fadeInUp">
                <div className="blog-thumb">
                  <img src={blog1} alt="blog-img" />
                </div>
                <div className="contents">
                  <div className="post-meta d-flex">
                    <div className="post-cat">
                      <a href="blog-three-col.html">Trends</a>
                    </div>
                    <div className="post-date">
                      <span>Apr. 7, 2024</span>
                    </div>
                  </div>
                  <h4>
                    <a href="blog-details.html">
                      Trending Trading To Empower Strategies, Make Decisions
                    </a>
                  </h4>

                  <a href="blog-details.html" className="read-more-link">
                    read more <i className="icon-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 col-12">
              <div className="blog-inner wow fadeInUp" data-wow-delay=".2s">
                <div className="blog-thumb">
                  <img src={blog2} alt="blog-img" />
                </div>
                <div className="contents">
                  <div className="post-meta d-flex">
                    <div className="post-cat">
                      <a href="blog-three-col.html">Analysis</a>
                    </div>
                    <div className="post-date">
                      <span>Jan. 9, 2024</span>
                    </div>
                  </div>
                  <h4>
                    <a href="blog-details.html">
                      Dive Analysis To Uncover Insights for Strategic
                    </a>
                  </h4>
                  <a href="blog-details.html" className="read-more-link">
                    read more <i className="icon-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 col-12">
              <div className="blog-inner wow fadeInUp" data-wow-delay=".4s">
                <div className="blog-thumb">
                  <img src={blog3} alt="blog-img" />
                </div>
                <div className="contents">
                  <div className="post-meta d-flex">
                    <div className="post-cat">
                      <a href="blog-three-col.html">Strategies</a>
                    </div>
                    <div className="post-date">
                      <span>Feb. 13, 2024</span>
                    </div>
                  </div>
                  <h4>
                    <a href="blog-details.html">
                      Powerful Trading Strategies To Boost Your Success
                    </a>
                  </h4>
                  <a href="blog-details.html" className="read-more-link">
                    read more <i className="icon-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <a href="/blogs" className="custom-btn">
              View All blogs
            </a>
          </div>
        </div>
      </section>

      {/* blog section end hare */}
    </div>
  );
};

export default Blog;
