import {
    GET_TEAM_DATA
  } from '../../utils/types';
  
  const initialState = {
    teamData: {},
    loading: true,
    error: {}
  };
  
  function teamReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_TEAM_DATA:
        return {
          ...state,
          teamData: payload,
          loading: false,
          error: {} 
        };
      default:
        return state;
    }
  }
  
  export default teamReducer;
  