import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../redux/actions/authActions";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPass: "",
    password: "",
    confirm_pass: "",
  });

  const dispatch = useDispatch();

  // Access user from Redux store
  const user = useSelector((state) => state.auth.user);
  const { currentPass, password, confirm_pass } = formData;

  // Set email from user object on component mount or when user changes
  const email = user?.email || "";

  useEffect(() => {
    if (!email) {
      alert("User email is not available. Please log in again.");
    }
  }, [email]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!currentPass || !password || !confirm_pass) {
      alert("Please fill in all fields.");
      return;
    }

    if (password.length < 8) {
      alert("Password must be at least 8 characters long.");
      return;
    }

    if (password !== confirm_pass) {
      alert("Passwords do not match.");
      return;
    }

    // Dispatch the changePassword action
    dispatch(changePassword(email, currentPass, password));
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-sm-12">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper ">
                <div className="user-card-thumb">
                  <h4 className="user-card-title">Change Password</h4>
                </div>
                <div className="user-card-content mt-2 text-start p-5">
                  <div className="card-body pt-3">
                    <form onSubmit={handleSubmit}>
                      <div className="row p-3">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="current-password"
                        >
                          Existing Password
                        </label>
                        <div className="col-sm-8">
                          <input
                            name="currentPass"
                            type="password"
                            value={currentPass}
                            onChange={onChange}
                            placeholder="Current Password"
                            className="form-control"
                            id="current-password"
                            required
                          />
                        </div>
                      </div>
                      <div className="row p-3">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="new-password"
                        >
                          New Password
                        </label>
                        <div className="col-sm-8">
                          <input
                            name="password"
                            type="password"
                            value={password}
                            onChange={onChange}
                            className="form-control"
                            id="new-password"
                            placeholder="New Password"
                            required
                          />
                        </div>
                      </div>
                      <div className="row p-3">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="confirm-password"
                        >
                          Confirm Password
                        </label>
                        <div className="col-sm-8">
                          <div className="input-group input-group-merge">
                            <input
                              name="confirm_pass"
                              type="password"
                              value={confirm_pass}
                              onChange={onChange}
                              className="form-control"
                              id="confirm-password"
                              placeholder="Confirm Password"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-grid gap-2 col-6 mx-auto pt-4 ">
                        <button
                          type="submit"
                          className="btn btn-danger btn-medium"
                        >
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
