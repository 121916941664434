import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitContactForm } from "../../redux/actions/contact";
import shape4 from "../../assets/img/shape/4.svg";

const Faq = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.message) errors.message = "Message is required";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(submitContactForm(formData));
    }
  };

  return (
    <div>
      {/* faq section start hare  */}

      <section
        className="faq-video-block section-padding section-bg overflow-hidden"
        id="faq"
      >
        <div className="container">
          <div
            className="nice-arrow-icon d-none d-lg-block wow fadeInDown"
            data-wow-duration="1.2s"
          >
            <img src={shape4} alt="3d bg shape" />
          </div>
          <div className="col-lg-6 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
            <div className="block-contents">
              <div
                className="section-title wow fadeInDown"
                data-wow-duration="1.2s"
              >
                <h2>Trading Questions, Answered Here</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 ps-xl-5 col-12 mt-xl-0 mt-5">
              <div className="faq-accordion  ms-xl-4 me-xl-4">
                <div className="accordion" id="mainaccordion">
                  <div
                    className="accordion-item wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".3s"
                  >
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqask1"
                        aria-expanded="false"
                        aria-controls="faqask1"
                      >
                        Is the LillyBot Trading Site Safe and Reliable?
                      </button>
                    </h4>
                    <div
                      id="faqask1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#mainaccordion"
                    >
                      <div className="accordion-body">
                        Yes, LillyBot is designed with your security in mind. We
                        employ advanced encryption technologies and robust
                        security protocols to protect your personal information
                        and investments. Our platform undergoes regular security
                        audits to ensure a safe trading environment.
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item wow fadeInUp"
                    data-wow-duration="1.1s"
                    data-wow-delay=".6s"
                  >
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqask2"
                        aria-expanded="true"
                        aria-controls="faqask2"
                      >
                        What Key Factors Should I Consider When Choosing a
                        LillyBot AI Trading Platform?
                      </button>
                    </h4>
                    <div
                      id="faqask2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#mainaccordion"
                    >
                      <div className="accordion-body">
                        When selecting an AI trading platform like LillyBot,
                        consider the following key factors: <br />
                        <br />
                        Algorithm Performance: Ensure the platform’s AI
                        algorithms are sophisticated and have a proven track
                        record of successful trades. <br />
                        <br />
                        Volume and Liquidity: A platform capable of handling
                        high-volume trades and providing liquidity is crucial
                        for effective trading. <br />
                        <br />
                        Security Measures: Look for platforms that prioritize
                        data encryption, secure transactions, and privacy.
                        <br />
                        <br /> Ease of Use: The platform should be
                        user-friendly, with an intuitive interface that allows
                        you to manage trades effortlessly. <br />
                        <br />
                        Customer Support: Reliable customer support is essential
                        for addressing any issues or questions that may arise.
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item wow fadeInUp"
                    data-wow-duration="1.1s"
                    data-wow-delay=".9s"
                  >
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqask3"
                        aria-expanded="false"
                        aria-controls="faqask3"
                      >
                        How Does LillyBot Ensure Accurate and Timely Trades?
                      </button>
                    </h4>
                    <div
                      id="faqask3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#mainaccordion"
                    >
                      <div className="accordion-body">
                        LillyBot utilizes advanced AI algorithms that analyze
                        market trends in real time. This ensures that trades are
                        executed with precision and at the optimal times to
                        maximize profitability. The platform’s continuous data
                        analysis helps it adapt to market changes and make
                        informed trading decisions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="faq-bottom ms-xl-4 mt-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".9s"
              >
                <span>Still have questions?</span>
                <a href="/contact">Get in touch</a>
              </div>
            </div>

            <div className="col-lg-5 pe-xl-5 col-12">
              <div
                className="question__form-wrapper"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                <h3>Seeking Information?</h3>
                <form className="question__form" onSubmit={handleSubmit}>
                  <div className="row g-4">
                    <div className="col-12">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Your name"
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Your email"
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        type="text"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="form-control form-control--message"
                        placeholder="Describe your message"
                      ></textarea>
                    </div>
                  </div>
                  <button
                    className="form-control custom-btn text-center mt-4 text-dark"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq section start hare  */}
    </div>
  );
};

export default Faq;
