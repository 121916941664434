import api from "../../utils/api";
import { toast } from "react-toastify";
import { GET_USDT_ADDRESS, GET_WALLET_DATA, WITHDRAW_REWARDS, CHECK_USDT_DEPOSIT } from "../../utils/types";

export const getTRONAddress = () => async (dispatch) => {
  try {
    const res = await api.post("/package/generate-wallet");
    dispatch({
      type: GET_USDT_ADDRESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
  }
};

export const getWalletData = () => async (dispatch) => {
  try {
    const res = await api.get("/wallet");
    dispatch({
      type: GET_WALLET_DATA,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
  }
};

export const withdrawRewards = (body, callback) => async (dispatch) => {
  try {
    const res = await api.post("/wallet/withdraw-rewards", body);
    // console.log(res);
    if (res.data.status === "OTP_SENT") {
      if (callback) callback("OTP_SENT");
    } else {
      if (callback) callback("SUCCESS");
      toast.success(`Withdrawal of $${body.amount} processed successfully!`);
      dispatch({
        type: WITHDRAW_REWARDS,
        payload: res.data.wallData,
      });
    }
  } catch (err) {
    if (callback) callback("ERROR");
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
  }
};

export const getDepositStatus = () => async (dispatch) => {
  try {
    const res = await api.get("/wallet/check-deposit");
    dispatch({
      type: CHECK_USDT_DEPOSIT,
      payload: res.data.wallData,
    });
  } catch (err) {
    const errors = err.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error.msg));
    }
  }
};


