import React from "react";
import Header from "./components/Header";
import Banner1 from "./components/Banner1";
import Features from "./components/Features";
import About from "./components/About";
import UsersDeatils from "./components/UsersDeatils";
import CryptoApp from "./components/CryptoApp";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import Faq from "./components/Faq";
import Blog from "./components/Blog";
import Promo from "./components/Promo";
import Price from "./components/Price";
import Team from "./components/Team";
import CryptoCurrency from "./components/CryptoCurrency";

const Index = () => {
  try {
    // Some code
  } catch (error) {
    console.error(error.message || error.toString());
  }
  return (
    <div>
      <Header />
      <Banner1 />
      <About />
      <Promo />
      <Price />
      <UsersDeatils />
      <Team />
      <CryptoApp />
      <Features />
      <CryptoCurrency />
      <Testimonials />
      <Faq />
      <Blog />
      <Footer />
    </div>
  );
};

export default Index;
