import React from "react";
import Promo from "./components/Promo";
import About from "./components/About";
import Token from "./components/Token";
import UsersDeatils from "./components/UsersDeatils";
import Testimonials from "./components/Testimonials";
import Team from "./components/Team";
import Footer from "./components/Footer";
import Header from "./components/Header";

const AboutUs = () => {
  return (
    <div>
      <Header />
      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap  text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h1>About Us</h1>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="breadcrumb-wrapper">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      {/* <!-- promo-content-block section start hare --> */}

      <Promo />

      {/* <!-- promo-content-block section end hare --> */}

      {/* <!-- about section start hare --> */}

      <About />

      {/* <!-- about section end hare --> */}

      {/* <!-- token section start hare --> */}

      <Token />

      {/* <!-- token section end hare -->  */}

      {/* <!-- user ditails secion start hare --> */}

      <UsersDeatils />

      {/* <!-- user data secion start hare --> */}

      {/* <!-- testimonial section start hare --> */}

      <Testimonials />

      {/* <!-- testimonial section end hare --> */}

      {/* <!-- team section start hare --> */}

      <Team />

      {/* <!-- team section end hare --> */}

      <Footer />
    </div>
  );
};

export default AboutUs;
