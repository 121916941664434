import React from "react";
import logo from "../../assets/img/logo/logo.png";

const Footer = () => {
  return (
    <div>
      {/* footer section start hare  */}

      <footer className="footer-wrapper footer-1">
        <div className="footer-widgets-wrapper">
          <div className="container">
            <div className="row g-5">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="single-footer-widget wow fadeInLeft">
                  <div className="about-us-widget">
                    <a href="/" className="footer-logo d-block">
                      <img src={logo} alt="TradePro" />
                    </a>
                    <p>
                      In the fast-paced world of cryptocurrency, timing and
                      precision are everything. Introducing LillyBot, your
                      advanced AI auto trading platform designed to optimize
                      high-volume crypto trades and deliver consistent profits
                      for our members.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 offset-xl-1 col-sm-6 col-12">
                <div
                  className="footer__widget wow fadeInLeft"
                  data-wow-delay=".2s"
                >
                  <h3 className="footer__widget-title">Company</h3>
                  <ul>
                    <li>
                      <a href="/about">About Us</a>{" "}
                    </li>
                    <li>
                      <a href="team">Our Team</a>{" "}
                    </li>
                    {/* <li>
                      <a href="">Press & Media</a>{" "}
                    </li> */}
                    <li>
                      <a href="/blogs">Blog</a>{" "}
                    </li>
                    <li>
                      <a href="/services">Services</a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 offset-xl-1 col-sm-6 col-12">
                <div
                  className="footer__widget wow fadeInLeft"
                  data-wow-delay=".4s"
                >
                  <h3 className="footer__widget-title">Support</h3>
                  <ul>
                    <li>
                      <a href="/contact">Help Center</a>{" "}
                    </li>
                    <li>
                      <a href="#faq">FAQs</a>{" "}
                    </li>
                    <li>
                      <a href="#">Community Forum</a>{" "}
                    </li>
                    <li>
                      <a href="#">Educations</a>{" "}
                    </li>
                    <li>
                      <a href="/contact">Email Support</a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 offset-xl-1 col-sm-6 col-12">
                <div
                  className="footer__widget wow fadeInLeft"
                  data-wow-delay=".6s"
                >
                  <h3 className="footer__widget-title">Resources</h3>
                  <ul>
                    <li>
                      <a href="#">
                        Trading Tools<span> (1)</span>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#">
                        Stock Screeners<span> (7)</span>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#">
                        Profit Charts<span> (11)</span>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#">
                        Charting Tools<span> (1)</span>
                      </a>{" "}
                    </li>
                    <li>
                      <a href="#">
                        Risk Calculators<span> (5)</span>
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-wrapper">
          <div className="container">
            <div className="footer-bottom-content d-md-flex justify-content-between">
              <div
                className="site-copyright wow fadeInUp"
                data-wow-delay=".2"
                data-wow-duration="1s"
              >
                <p>
                  &copy; 2024 <a href="/">LillyBot</a> All Rights Reserved.
                </p>
              </div>
              <div
                className="social-links mt-4 mt-md-0 wow fadeInUp"
                data-wow-delay=".3"
                data-wow-duration="1s"
              >
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-telegram-plane"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer section end hare */}
    </div>
  );
};

export default Footer;
