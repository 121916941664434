import React from "react";
import shape1 from "../../assets/img/shape/1.svg";
import currency1 from "../../assets/img/currency/1.png";
import currency2 from "../../assets/img/currency/2.png";
import currency3 from "../../assets/img/currency/3.png";
import currency4 from "../../assets/img/currency/4.png";
import currency5 from "../../assets/img/currency/5.png";
import currency6 from "../../assets/img/currency/6.png";

const CryptoCurrency = () => {
  return (
    <div>
      {/* <!-- crypto currency section start hare --> */}

      <section className="crypto-currencies-wrapper fix  section-padding section-bg-5">
        <div className="container">
          <div className="col-lg-8 col-xl-6 offset-xl-3 offset-lg-2 text-center">
            <div className="block-contents text-white">
              <div
                className="section-title wow fadeInUp"
                data-wow-duration="1s"
              >
                <h2>The most popular cryptocurrencies</h2>
              </div>
            </div>
          </div>
          <div
            className="nice-arrow-icon d-none d-lg-block wow fadeInDown"
            data-wow-duration="1.2s"
          >
            <img src={shape1} alt="3d bg shape" />
          </div>

          <div className="row">
            <div className="col-md-6 col-xl-4">
              <div
                className="single-crypto-currency-card single-crypto-currency-card--style2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="currency-header">
                  <div className="currency-thumb">
                    <img src={currency1} alt="Bitcoin" />
                  </div>
                  <div className="currency-name">
                    <h6>Bitcoin (BTC)</h6>
                    <span>Cryptocurrency</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div
                className="single-crypto-currency-card single-crypto-currency-card--style2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="currency-header">
                  <div className="currency-thumb">
                    <img src={currency2} alt="Bitcoin" />
                  </div>
                  <div className="currency-name">
                    <h6>Ethereum (ETH)</h6>
                    <span>Cryptocurrency</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div
                className="single-crypto-currency-card single-crypto-currency-card--style2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".6s"
              >
                <div className="currency-header">
                  <div className="currency-thumb">
                    <img src={currency3} alt="Chainlink" />
                  </div>
                  <div className="currency-name">
                    <h6>Chainlink (LINK)</h6>
                    <span>Cryptocurrency</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div
                className="single-crypto-currency-card single-crypto-currency-card--style2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".1s"
              >
                <div className="currency-header">
                  <div className="currency-thumb">
                    <img src={currency4} alt="Litecoin" />
                  </div>
                  <div className="currency-name">
                    <h6>Litecoin</h6>
                    <span>Cryptocurrency</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div
                className="single-crypto-currency-card single-crypto-currency-card--style2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <div className="currency-header">
                  <div className="currency-thumb">
                    <img src={currency5} alt="Binance Coin" />
                  </div>
                  <div className="currency-name">
                    <h6>DigiByte</h6>
                    <span>Cryptocurrency</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div
                className="single-crypto-currency-card single-crypto-currency-card--style2 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".6s"
              >
                <div className="currency-header">
                  <div className="currency-thumb">
                    <img src={currency6} alt="Tether" />
                  </div>
                  <div className="currency-name">
                    <h6>Monero</h6>
                    <span>Cryptocurrency</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- crypto currency section end hare --> */}
    </div>
  );
};

export default CryptoCurrency;
