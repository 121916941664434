import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitContactForm } from "../redux/actions/contact";
import Header from "./components/Header";
import Footer from "./components/Footer";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.message) errors.message = "Message is required";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(submitContactForm(formData));
    }
  };
  return (
    <div>
      {/* <!-- header section start hare --> */}
      <Header />
      {/* <!-- header section end hare --> */}

      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap bg-cover text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3 text-center text-white">
              <div className="page-heading">
                <h1>Contact Us</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      {/* Contact Section */}

      <section className="contact-us-wrapper section-padding">
        <div className="container">
          <div className="row mt-3 mt-lg-5">
            <div className="col-lg-8 col-xl-6 offset-xl-3 offset-lg-2 text-center">
              <div className="block-contents">
                <div className="section-title mb-4">
                  <h2 className="wow fadeInUp">Get in touch now!</h2>
                  <p className="wow fadeInUp pt-15" data-wow-delay=".3s">
                    Is there an inquiry or some feedback for us? Fill out the
                    form to contact our team. We love to hear from you.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 pe-lg-4 order-2 order-lg-1">
              <div className="contact-thumb me-lg-4 wow fadeInUp">
                <img src="assets/img/contact/1.png" alt="contact" />
              </div>
            </div>
            <div className="col-lg-6 pls-lg-4 wow fadeInUp order-1 order-lg-2">
              <div
                className="question__form-wrapper question__form-wrapper--style-2"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                <h3>Seeking Information?</h3>
                <form className="question__form" onSubmit={handleSubmit}>
                  <div className="row g-4">
                    <div className="col-12">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Your name"
                        aria-label="Name"
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Your email"
                        aria-label="Email"
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Subject"
                        aria-label="Subject"
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="form-control form-control--message"
                        placeholder="Describe your message"
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="form-control custom-btn text-center text-dark mt-4"
                  >
                    Message Submit
                  </button>
                </form>
                <p className="form-message mt-4"></p>
              </div>
            </div>
          </div>

          <div className="row text-center">
            <div className="col-md-6 col-xl-4">
              <div className="single-contact-box ">
                <div className="contact-thumb">
                  <img
                    src="assets/img/contact/icon/1.png"
                    alt="contact-icon-1"
                  />
                </div>
                <div className="contact-info">
                  <span>+088-123-456-789</span>
                  <span>+088-321-654-789</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="single-contact-box">
                <div className="contact-thumb">
                  <img
                    src="assets/img/contact/icon/2.png"
                    alt="contact-icon-2"
                  />
                </div>
                <div className="contact-info">
                  <span>info@lillybot.io</span>
                  <span>admin@lillybot.io</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="single-contact-box ">
                <div className="contact-thumb">
                  <img
                    src="assets/img/contact/icon/1.png"
                    alt="contact-icon-1"
                  />
                </div>
                <div className="contact-info">
                  <span>0123 Thikhasago, London.</span>
                  <span>1342 Rd. webtown, German.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  Contact Section End */}

      <Footer />
    </div>
  );
};

export default ContactUs;
