import React from "react";

import styles from "../custom.module.css";

import userCard from "../../assets/img/user/card/green/01.png";
import userCard2 from "../../assets/img/user/card/green/02.png";
import userCard3 from "../../assets/img/user/card/green/03.png";
import userCard4 from "../../assets/img/user/card/green/04.png";

const WalletData = ({ walletData, teamData }) => {
  let totalInvestment = 0;
  if (walletData?.GrowthData?.updatedPackagesData) {
    const packgesData = walletData?.GrowthData?.updatedPackagesData;
    packgesData.forEach((element) => {
      totalInvestment += element.Package.pkg_amount;
    });
  }
  return (
    <div>
      <section className="user-ditails-wrapper fix section-bg-5 section-padding-medium">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper ">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Investment
                        </div>
                        <p>${totalInvestment.toFixed(2)}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Earning
                        </div>
                        <p>${walletData?.GrowthData?.totalGrowth || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard2} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Wallet Balance
                        </div>
                        <p>${walletData?.walletBalance || 0.0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Withdrawal
                        </div>
                        <p>${walletData?.totalWithAmt || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard3} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Credit
                        </div>
                        <p>${walletData?.totalCredit || 0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>Total Debit</div>
                        <p>${walletData?.totalDebit || 0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard4} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Income
                        </div>
                        <p>${teamData?.directIncome || 0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Earning
                        </div>
                        <p>${teamData?.totalIncome || 0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WalletData;
