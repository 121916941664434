import React from "react";
import Header from "./components/Header";
import promo2 from "../assets/img/promo/2.png";
import shape4 from "../assets/img/shape/4.svg";
import icon1 from "../assets/img/service/icons/1.png";
import icon2 from "../assets/img/service/icons/2.png";
import icon3 from "../assets/img/service/icons/3.png";
import icon4 from "../assets/img/service/icons/4.png";
import icon5 from "../assets/img/service/icons/5.png";
import icon6 from "../assets/img/service/icons/6.png";
import UsersDeatils from "./components/UsersDeatils";
import Price from "./components/Price";
import Faq from "./components/Faq";
import Footer from "./components/Footer";

const Services = () => {
  return (
    <div>
      {/* <!-- header section start hare --> */}
      <Header />
      {/* <!-- header section end hare --> */}

      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap bg-cover text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3 text-center text-white">
              <div className="page-heading">
                <h1>Our Services</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Our Services
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      {/* <!-- promo section start hare --> */}

      <section className="promo-section section-padding section-bg">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6">
              <div className="promo-thumb">
                <img className=" rounded" src={promo2} alt="mobile app" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="promo-content mt-lg-5 ps-lg-5">
                <h2>Crypto Trading Zone</h2>
                <div className="features-list">
                  <ul>
                    <li>
                      <img className="me-2" src={icon1} alt="right-arrow" />
                      Bitcoin Trading and Tips.
                    </li>
                    <li>
                      <img className="me-2" src={icon1} alt="right-arrow" />
                      Bitcoin Trading and Signals.
                    </li>
                    <li>
                      <img className="me-2" src={icon1} alt="right-arrow" />
                      Bitcoin Trading and News.
                    </li>
                  </ul>
                </div>

                <div className="promo-btn mt-5">
                  <a href="signup.html" className="custom-btn">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- promo section end hare --> */}

      {/* <!-- service section start hare --> */}

      <section className="our-best-features-wrapper section-padding">
        <div className="container">
          <div
            className="nice-arrow-icon d-none d-lg-block wow fadeInDown"
            data-wow-duration="1.2s"
          >
            <img src={shape4} alt="3d bg shape" />
          </div>
          <div className="col-xl-8 offset-xl-2 text-center">
            <div className="block-contents">
              <div className="section-title">
                <h2>Our Key Trading Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="features-card-item style-1">
                <div className="icon">
                  <img src={icon1} alt="Icon" />
                </div>
                <h3>
                  <a href="/services">Stock Analysis</a>
                </h3>
                <p>
                  Our stock analysis service provides you with expert insights
                  into the stock market, helping you make informed investment
                  decisions.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="features-card-item style-1">
                <div className="icon">
                  <img src={icon2} alt="Icon" />
                </div>
                <h3>
                  <a href="/services">Forex Trading</a>
                </h3>
                <p>
                  Experience the world of foreign exchange trading with our
                  Forex trading advisory service, tailored to your financial
                  goals.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="features-card-item style-1">
                <div className="icon">
                  <img src={icon3} alt="Icon" />
                </div>
                <h3>
                  <a href="/services">Crypto Insights</a>
                </h3>
                <p>
                  Stay ahead in the fast-paced world of cryptocurrencies with
                  our comprehensive cryptocurrency insights and recommendations.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="features-card-item style-1">
                <div className="icon">
                  <img src={icon4} alt="Icon" />
                </div>
                <h3>
                  <a href="services-details.html">Options Trading</a>
                </h3>
                <p>
                  Explore the world of options trading with our expert guidance,
                  helping you harness the potential of derivatives trading
                  business.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="features-card-item style-1">
                <div className="icon">
                  <img src={icon5} alt="Icon" />
                </div>
                <h3>
                  <a href="/services">Algorithmic Trading</a>
                </h3>
                <p>
                  Maximize your trading efficiency with our algorithmic trading
                  services, leveraging automation and data-driven strategies.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="features-card-item style-1">
                <div className="icon">
                  <img src={icon6} alt="Icon" />
                </div>
                <h3>
                  <a href="/services">Trading Invest</a>
                </h3>
                <p>
                  Gain insights into the commodities market and optimize your
                  investments with our commodity market analysis service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- service section start hare --> */}

      {/* <!-- user ditails secion start hare --> */}

      <UsersDeatils />

      {/* <!-- user data secion start hare --> */}

      {/* <!-- price section start hare --> */}

      <Price />
      {/* <!-- price section end hare --> */}

      {/* <!-- faq section start hare --> */}

      <Faq />

      {/* <!-- faq section start hare --> */}

      <Footer />
    </div>
  );
};

export default Services;
