import {
  GET_USDT_ADDRESS,
  GET_WALLET_DATA,
  WITHDRAW_REWARDS,
  CHECK_USDT_DEPOSIT
} from '../../utils/types';

const initialState = {
  addressData: {
    address: null,
    qrCode: null,
  },
  walletData: {},
  loading: true,
  error: {}
};

function walletReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USDT_ADDRESS:
      return {
        ...state,
        addressData: payload,
        loading: false,
        error: {} // Clear errors on successful fetch
      };

    case GET_WALLET_DATA:
      return {
        ...state,
        walletData: payload,
        loading: false,
        error: {} // Clear errors on successful fetch
      };

    case WITHDRAW_REWARDS:
      return {
        ...state,
        walletData: { ...state.walletData, ...payload }, 
        loading: false,
        error: {}
      };
    case CHECK_USDT_DEPOSIT:
      return {
        ...state,
        walletData: { ...state.walletData, ...payload }, // Merge payload to preserve other wallet data
        loading: false,
        error: {}
      };
    default:
      return state;
  }
}

export default walletReducer;
