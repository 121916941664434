import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../home/components/Header";
import Footer from "../home/components/Footer";
import invalidLink from "../assets/img/account/invalid-link.png";

const InvalidLink = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h1>Invalid Verification</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Invalid Verification
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}
      <section className="promo-content-block fix section-padding section-bg-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 mt-5 mt-xl-0 order-2 order-xl-1">
              <div className="block-contents">
                <div className="section-title mb-4">
                  <h2 className="wow fadeInUp">
                    Sorry !!! Invalid or Expired Verification Link..
                  </h2>
                  <p className="wow fadeInUp pt-15" data-wow-delay=".3s">
                    Click on the button below to proceed to login.
                  </p>
                  <button
                    name="signin"
                    type="button"
                    className="d-block custom-btn move-top"
                    onClick={() => navigate("/signin")}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 order-1 order-xl-2">
              <img src={invalidLink} alt="Clients" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default InvalidLink;
