import React from "react";
// import bg from "../../assets/img/banner/hero/1.png";

const Banner1 = () => {
  return (
    <div>
      {/* <!-- banner section start hare --> */}

      <section className="hero-welcome-wrapper hero-3 ">
        <div className="single-slide text-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10">
                <div className="hero-contents text-center">
                  <div>
                    <h1 className="color--theme-color2">Lilly Bot</h1> <br />{" "}
                    <h2>Your AI-Powered Crypto Trading Partner</h2>{" "}
                  </div>
                  <h2>Maximize Your Profits with Cutting-Edge Automation</h2>
                  <a href="/signup" className="custom-btn  custom-btn--green">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- banner section end hare --> */}
    </div>
  );
};

export default Banner1;
