import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import store from "../redux/store";
import { toast } from "react-toastify"; 

import formatDate from "../utils/formatDate";
import { getActivateUserPackages } from "../redux/actions/package";

import UserSubHeader from "./components/UserSubHeader";
import UserData from "./components/UserData";
import UserHeader from "./components/UserHeader";
import UserFooter from "./components/UserFooter";

import styles from "./custom.module.css";

const Dashboard = ({ getActivateUserPackages, activeUserPackages }) => {
  const [subTitle, setsubTitle] = useState("");
  useEffect(() => {
    setsubTitle("Lilly BoT Dashboard");
    console.log("Redux state:", store.getState());
  }, [store.getState()]);

  const [pkgName, setPkgName] = useState("");
  const [pkgAmount, setPkgAmount] = useState("");
  const [dailyGrowth, setdailyGrowth] = useState("");
  const [dailyCapping, setdailyCapping] = useState("");
  const [activationDate, setactivationDate] = useState("");

  useEffect(() => {
    getActivateUserPackages();
  }, [getActivateUserPackages]);

  console.log(activeUserPackages);

  useEffect(() => {
    const userActivePkg = activeUserPackages; // Safe access to the first element
    // console.log("User Package", activeUserPackages.length);
    if (userActivePkg.length) {
      setPkgName(userActivePkg[userActivePkg.length - 1].Package.pkg_name); // Setting state in useEffect
      
      setdailyGrowth(
        userActivePkg[userActivePkg.length - 1].Package.growth_per
      );
      setdailyCapping(
        userActivePkg[userActivePkg.length - 1].Package.per_day_income_capping
      );
      setactivationDate(
        formatDate(userActivePkg[userActivePkg.length - 1].created_on)
      );
      console.log(userActivePkg[userActivePkg.length - 1].created_on);
    } else {
      console.log("No active package found");
    }
  }, [activeUserPackages]);

  const user = useSelector((state) => state.auth.user);

  const generateReferralUrl = () => {
    const url = `https://lillybot.io/signup`;
    return `${url}/${user ? user.referral_id : "Generating Link"}`;
  };

  const handleCopy = () => {
    const referralUrl = generateReferralUrl();
    navigator.clipboard.writeText(referralUrl);
    toast.success("Referral Link copied to clipboard!");
  };

  return (
    <div>
      <UserHeader />
      <UserSubHeader pageTitle={"Dashboard"} subTitle={subTitle} />
      <div className="section-padding-medium">
      <div className="container text-center">
      {activeUserPackages.length > 0 ? (
            <>
              <div className="fs-6 m-3">Your Referral Link</div>

              <button
                onClick={handleCopy}
                className="btn btn-sm btn-outline-primary"
                title="Share Your Referral Link :"
              >
                <div className={styles.refLink}>{generateReferralUrl()}</div>
              </button>
            </>
          ) : (
            <div className="alert alert-danger alert-dismissible" role="alert">
              <span>You Need Active Package to refer New Member !</span>
              <button
                type="button"
                className="btn-close text-danger"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
      </div>
      </div>
      <UserData />
      <UserFooter />
    </div>
  );
};


Dashboard.propTypes = {
  getActivateUserPackages: PropTypes.func.isRequired,
  activeUserPackages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  activeUserPackages: state.packages.activeUserPackages,
});

export default connect(mapStateToProps, { getActivateUserPackages })(
  Dashboard
);
