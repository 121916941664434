import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import UserHeader from "./components/UserHeader";
import UserSubHeader from "./components/UserSubHeader";
import TeamData from "./components/TeamData";
import UserFooter from "./components/UserFooter";


const Team = () => {
 
  return (
    <div>
      <UserHeader />
      <UserSubHeader pageTitle={"Team"} subTitle={"Direct Team Report"} />
      <TeamData/>
      <UserFooter />
    </div>
  );
};

export default Team;
