import { GET_PACKAGES, GET_ACTIVE_PACKAGES } from '../../utils/types';

const initialState = {
    allPackages: [],
    activeUserPackages: [],
    loading: true,
    error: {}
};

function packagesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PACKAGES:
            console.log("GET_PACKAGES payload:", payload);
            return {
                ...state,
                allPackages: payload,
                loading: false
            };
        case GET_ACTIVE_PACKAGES:
            console.log("GET_ACTIVE_PACKAGES payload:", payload);
            return {
                ...state,
                activeUserPackages: payload,
                loading: false
            };
        default:
            return state;
    }
}

export default packagesReducer;
