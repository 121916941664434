import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { forgotPassSendMail } from "../redux/actions/authActions";
import Header from "../home/components/Header";

const ForgotPassMail = ({ forgotPassSendMail }) => {
  const [isRequest, setIsRequest] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [formData, setFormData] = useState({ email: "" });
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const { email } = formData;

  // console.log(formData);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsRequest(true);
    forgotPassSendMail(email, (success) => {
      if (success) {
        setRedirect(true);
      } else {
        setIsRequest(false);
      }
    });
  };
  return (
    <div>
      <Header />
      {/* <!-- banner section start hare --> */}

      <div className="page-banner-wrap  text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h1>Reset Password</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Reset Password
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- banner section end hare --> */}

      {/* <!-- ==========Reset pass Section start Here========== --> */}
      <div className="account section-padding">
        <div className=" container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="account__wrapper">
                <h3 className="title">Forgot Password?</h3>
                {redirect ? (
                  <label htmlFor="floatingInput">
                    Please check your mail address, we have send forgot password
                    link!
                  </label>
                ) : (
                  <>
                    <form className="account__form" onSubmit={onSubmit}>
                      <div className="form-floating mb-4">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="floatingInput"
                          onChange={onChange}
                          placeholder="name@example.com"
                        />
                        <label htmlFor="floatingInput">Email address</label>
                      </div>
                      <div className="form-group">
                        {!isRequest ? (
                          <button
                            name="submit"
                            type="submit"
                            className="d-block custom-btn move-top"
                          >
                            Send Reset Code
                          </button>
                        ) : (
                          <button
                            name=""
                            className="d-block custom-btn move-top"
                            disabled
                          >
                            Please Wait...
                          </button>
                        )}
                      </div>
                    </form>
                  </>
                )}
                <div className="account-bottom">
                  <p className="d-block cate pt-10">
                    Have an account? <a href="/signin"> Sign In</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="account-img text-center">
                <img
                  src="assets/img/account/forgot-pass.png"
                  alt="account vector"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ==========signup Section ends Here========== --> */}
    </div>
  );
};

ForgotPassMail.propTypes = {
  forgotPassSendMail: PropTypes.func.isRequired,
};

export default connect(null, { forgotPassSendMail })(ForgotPassMail);
